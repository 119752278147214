import { forwardRef } from "react";
import { useSelector } from "react-redux";
import { selectUser } from "../../app/slices/userSlice";
import folder from "../images/folder.gif";
import moment from "moment";
import "./Messages.css";
const Messages = ({ contents, index }) => {
  let result = false;
  if (contents.input?.length > 0) {
    let newInput = contents.input.toLowerCase();
    result = newInput.includes("files".toLowerCase());
  }

  const isContain = contents.input.indexOf("@mehta");
  console.log(isContain);

  const user = useSelector(selectUser);
  return (
    <div
      key={index}
      className={`message ${
        user.email === contents.email && "message__sender"
      }`}
    >
      <img src={contents.photo} className="message__photo" />

      {!contents.input ? (
        <img className="message__Images" src={contents.urls} />
      ) : result ? (
        <>
          <img src={contents.folder} alt="folder" style={{ width: "2rem" }} />

          <h5>{contents.input}</h5>
        </>
      ) : (
        <p>{contents.input}</p>
      )}
      <small>
        {moment(contents.createdAt).fromNow()} by{" "}
        <span style={{ color: "yellow", fontSize: "12px" }}>
          @{contents.name}
        </span>
      </small>
    </div>
  );
};

export default Messages;
