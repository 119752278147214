import "./ProjectsTable.css";
import { oneDay, prsDate } from "../libs/time";
import Button from "./Button";
import { useState } from "react";
import { RiCloseCircleLine } from "react-icons/ri";
import { MdOutlineDelete } from "react-icons/md";
import { IoIosArrowDropdown } from "react-icons/io";
import { ToastContainer } from "react-toastify";
import { useResource } from "../hooks/useResource";
import Modals from "./Modals";
import { useToggle } from "../hooks/useToggle";
import { BsFillNodePlusFill } from "react-icons/bs";
import HalfPageModal from "./HalfPageModal";
import { useDispatch } from "react-redux";
import { setProjectToggle } from "../app/slices/projectToggleSlice";
import CommonModal from "./CommonModal";
import { MdGroup } from "react-icons/md";
import { AiOutlineNotification } from "react-icons/ai";
import { FiEdit2 } from "react-icons/fi";
import { ReactToolTipData } from "../libs/ReactToolTipData";

import {
  handleAssignTeam,
  deleteProject,
} from "../components/Admin/utils/projectTableUtils";
import EditSingleProject from "./EditSingleProject";

function ProjectsTable({ data, handleChange = false }) {
  const [taskId, setTaskId] = useState(null);
  const [selectedProject, setSelectedProject] = useState([]);

  const [team, setTeam] = useState(false);

  const { toggle: modal, toggleStatus: toggleModal } = useToggle();
  const { toggle: showButton, toggleStatus: toggleShow } = useToggle();

  const { toggle: modalOpen, toggleStatus: toggleModalOpen } = useToggle();
  const { toggle: projectOpen, toggleStatus: toggleProjectOpen } = useToggle();
  const { toggle: editProject, toggleStatus: toggleEditProject } = useToggle();
  const dispatch = useDispatch();
  const { toggle: statusButton, toggleStatus: toggleStatusButton } =
    useToggle();

  const handleAssignButton = (id, data) => {
    toggleShow();
    setTaskId(id);
    toggleModal();
    setSelectedProject(data);
  };

  const teamsData = useResource(process.env.REACT_APP_TEAMS);
  const toggleProject = async (data) => {
    dispatch(
      setProjectToggle({
        id: data.id,
        name: data.name,
        photo: data.photo,
        createdBy: data.createdBy,
        status: data.status,
        teamId: data.teamId,
        teamName: data.team,
      })
    );
    toggleProjectOpen();
  };
  const statusData = ["Done", "Working", "Stuck", "Not Started"];

  return (
    <>
      <ToastContainer
        astContainer
        position="top-right"
        autoClose={5000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
      />
      {team && (
        <CommonModal
          styleClass="create__ChatModal scale-up-ver-center project__AddTeams"
          onClose={() => setTeam(!team)}
        >
          <div className="add__Teams">
            <h2 className="h2__Title">Select Team</h2>
            {teamsData?.map((team) => (
              <div
                className="project__TeamsTable background__Danger"
                key={team?.id}
              >
                <h5 className="addTeams__Title">{team?.name}</h5>

                <div className="project__TeamsTable__Image">
                  {team.users.map((user) => (
                    <img
                      src={user?.photoURL}
                      className="message__photo"
                      key={user.id}
                    />
                  ))}
                </div>
                {team?.assigned ? (
                  "Assigned"
                ) : (
                  <Button
                    value="Select Team"
                    onClick={() =>
                      handleAssignTeam(
                        team,
                        taskId,
                        selectedProject,
                        toggleModal
                      )
                    }
                  />
                )}
              </div>
            ))}
          </div>
        </CommonModal>
      )}

      {modal && (
        <CommonModal
          styleClass="modal__Bottom scale-up-ver-center"
          onClose={toggleModal}
        >
          <img src={selectedProject.photo} className="profile" />
          <h2 className="common__Title">You Selected {selectedProject.name}</h2>

          <MdGroup
            className="h2__Title cursor__Pointer"
            onClick={() => setTeam(true)}
            data-tip
            data-for="assignTeam"
          />
          <FiEdit2
            onClick={toggleEditProject}
            className="common__Title cursor__Pointer"
            data-tip
            data-for="editItem"
          />
          <MdOutlineDelete
            onClick={() => toggleModalOpen()}
            className="h2__Title danger__Color cursor__Pointer"
            data-tip
            data-for="deleteItem"
          />
          <ReactToolTipData id="deleteItem">
            <h5 className="toolTip__Color">Delete Item</h5>
          </ReactToolTipData>
          <ReactToolTipData id="editItem">
            <h5 className="toolTip__Color">Edit Item</h5>
          </ReactToolTipData>
          <ReactToolTipData id="assignTeam">
            <h5 className="toolTip__Color">Assign Team</h5>
          </ReactToolTipData>
        </CommonModal>
      )}
      <div className="div1" key={data.id}>
        <div className="projects__Title">
          <h3
            className="verySmall__Fonts project__TaskTitle"
            onClick={() => handleAssignButton(data.id, data)}
            data-tip
            data-for="projectHoverTip"
          >
            {data.name}
          </h3>
          <ReactToolTipData id="projectHoverTip">
            <h5 className="toolTip__Color">💡Click To Edit</h5>
          </ReactToolTipData>

          <BsFillNodePlusFill onClick={() => toggleProject(data)} />
        </div>

        <div>
          <div
            onClick={() => toggleStatusButton()}
            className="dropDown__Header"
          >
            <h3
              className="verySmall__Fonts projects__Status"
              data-tip
              data-for="currentStatus"
            >
              {data.status}
            </h3>
            <ReactToolTipData id="currentStatus" backColor="">
              <h5 className="toolTip__Color">Current Status</h5>
            </ReactToolTipData>

            <h5 className="medium__Fonts">
              {statusButton ? <RiCloseCircleLine /> : <IoIosArrowDropdown />}
            </h5>
          </div>
          {statusButton && (
            <ul className="projectTable__Sticky">
              {statusData
                .filter((obj) => obj !== data.status)
                .map((item) => (
                  <li
                    className="dropDown__List h5__Color h5__Font"
                    key={item}
                    value={item}
                    onClick={() => {
                      handleChange(data.id, item);
                      toggleStatusButton();
                    }}
                  >
                    {item}
                  </li>
                ))}
            </ul>
          )}
        </div>
        <h4 className="verySmall__Fonts">
          {Math.round((data.endDate - prsDate) / oneDay)} days to go
        </h4>
        {data.priority > 6 ? (
          <h4 className="verySmall__Fonts projects__Priority">
            {data.priority}
          </h4>
        ) : (
          <h4 className="verySmall__Fonts projects__Priority1">
            {data.priority}
          </h4>
        )}
        <h4 className="verySmall__Fonts">{data.tags}</h4>

        <div className="assign__ImageWidth">
          <img
            src={data.photo}
            className="assign__Image assign__ImageMargin"
            alt={data?.name}
          />
        </div>
      </div>
      {modalOpen && (
        <Modals
          title={`Proceed with delete ${data.name}?`}
          value="Delete"
          onClick={() => deleteProject(data, toggleModalOpen)}
          onCancel={() => toggleModalOpen()}
          actionTitle="Delete Project"
        />
      )}
      {projectOpen && <HalfPageModal toggleProjectOpen={toggleProjectOpen} />}

      {editProject && (
        <CommonModal
          styleClass="scale-up-ver-center modals__Project"
          onClose={toggleEditProject}
        >
          <EditSingleProject selectedProject={selectedProject} />
        </CommonModal>
      )}
    </>
  );
}

export default ProjectsTable;
