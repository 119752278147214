import { prsDate, oneDay, daysInCurrentMonth } from "../../../libs/daysLeft";

export const nextMonthResultData = (projectData) => {
  // console.log(prsDate);
  const result = projectData.filter(
    (data) =>
      Math.round((data.endDate - new Date().getTime()) / oneDay) >
      daysInCurrentMonth
  );

  if (result.length === 0) return;
  const sortedResult = result.sort((a, b) => b.startData - a.startData);
  return sortedResult;
};

export const currentMonthResultData = (projectData) => {
  const result = projectData.filter(
    (data) =>
      Math.round((data.endDate - prsDate) / oneDay) <= daysInCurrentMonth
  );

  if (result.length === 0) return;

  const sortedResult = result.sort((a, b) => a.startDate - b.startDate);

  return sortedResult;
};

