import { doc, setDoc } from "firebase/firestore";
import { useState } from "react";
import { db } from "../components/firebase";
import { useToggle } from "../hooks/useToggle";

function StatusButton({ status, id, desiredStatus }) {
  const { toggle: statusButton, toggleStatus: toggleStatusButton } =
    useToggle();
  const statusData = ["Done", "Working", "Stuck"];

  const handleChange = async (data) => {
    const alertData = [
      {
        email: "mehtasagar95@gmail.com",
        name: "mehtasagar",
      },
      {
        email: "pravinmehta31@gmail.com",
        name: "pravinmehta",
      },
    ];

    const projectRef = doc(db, "projects", id);

    setDoc(
      projectRef,
      {
        emails: alertData,
        desiredStatus: data,
      },
      { merge: true }
    );
  };
  return (
    <div>
      <h5
        className="h5__Color common__Border__Bottom cursor__Pointer"
        onClick={toggleStatusButton}
      >
        {desiredStatus ? desiredStatus : "Select action"}
      </h5>
      {statusButton && (
        <ul className="projectTable__Sticky">
          {statusData
            .filter((obj) => obj !== status)
            .map((item) => (
              <li
                className="dropDown__List h5__Color h5__Font"
                key={item}
                value={item}
                onClick={() => {
                  handleChange(item);
                  toggleStatusButton();
                }}
              >
                {item}
              </li>
            ))}
        </ul>
      )}
    </div>
  );
}

export default StatusButton;
