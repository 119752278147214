import { useState } from "react";
import "./Notification.css";

import Header from "../Header";
import Footer from "../Footer";
import StatusButton from "../../libs/StatusButton";
import AdminSidebar from "../Admin/AdminSidebar";
function Notification() {
  // const resultData = data
  //   .slice(0, 100)
  //   .map((logs) => logs.jsonPayload.remoteIpCity.toUpperCase());

  // const count = resultData.reduce((accumulator, value) => {
  //   return { ...accumulator, [value]: (accumulator[value] || 0) + 1 };
  // }, {});

  // console.log(Object.entries(count));
  // let arr = [];
  // for (var i = 0; i < resultData.length; i++) {
  //   const dara = Math.floor(Math.random() * 16777215)
  //     .toString(16)
  //     .toString();
  //   console.log(dara.toString());

  //   arr.push(dara);
  //   console.log(arr);
  // }

  return (
    <>
      {/* <AdminSidebar /> */}
      <div className="notification">
        <Header />
        {/* {Object.entries(count).map((dat2) => (
          <div key={dat2}>
            <h2 className="common__Title">{dat2[0]}</h2>

            <h2 className="common__Title">{dat2[1]}</h2>
          </div>
        ))} */}
      </div>
      <Footer />
    </>
  );
}

export default Notification;
