import { oneDay, prsDate } from "../../libs/time";

const TimelineItem = ({ data }) => {
  return (
    <div className="timeline-item">
      <div
        className="timeline-item-content"
        style={{
          backgroundColor: `${
            Math.round((data.endDate - prsDate) / oneDay) > 30
              ? "#ffc505"
              : // "#FFC505"
                // : "#FF3B3F"
                "#6c6cff"
          }`,
        }}
      >
        <span className="tag">{data.tags}</span>
        {/* <span className="tag">{data.name}</span> */}
        <time>Due:{new Date(data?.endDate).toDateString()}</time>
        <h5 className="plain__White">{data.name}</h5>
        <p
          style={{
            backgroundColor: `${
              data.status === "Done" ? "#004DFF" : "#8B0004"
            }`,
            padding: "6px",
            fontSize: "12px",
            borderRadius: "20px",
            color: "#ffffff",
          }}
        >
          {data.status}
        </p>

        <img src={data.photo} className="message__photo" />
        <p className="verySmall__Fonts">{data.createdBy}</p>
        <p className="danger">{data.priority}</p>
        <p>{data?.team}</p>

        <span className="circle" />
      </div>
    </div>
  );
};
export default TimelineItem;
