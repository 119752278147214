import { useEffect } from "react";
import "./Docs.css";
import { MdOutlineClose } from "react-icons/md";
import { BsThreeDots } from "react-icons/bs";
import { storage } from "../firebase";
import { ImFilesEmpty } from "react-icons/im";
import { useState } from "react";
import Button from "../../libs/Button";
import LoadingSvg from "../images/loadingSvg.svg";

import { handleRemoveSelectedItem } from "../../libs/commonFunctions";
import { ref, listAll, getDownloadURL, getMetadata } from "firebase/storage";

import { handleDeleteFile, handleUpload } from "../../libs/commonFunctions";

import { AiFillCloseCircle } from "react-icons/ai";
import { useSelector } from "react-redux";
import { selectProjectToggle } from "../../app/slices/projectToggleSlice";
import { selectUser } from "../../app/slices/userSlice";
function Docs({ projectToggleData }) {
  const imageListRef = ref(storage, `projectsfiles/${projectToggleData.name}`);
  const [imageList, setImageList] = useState([]);
  const [images, setImages] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [names, setNames] = useState([]);
  const signedInUser = useSelector(selectUser);

  useEffect(() => {
    // let loading = true;
    const listAllImages = () => {
      listAll(imageListRef).then((response) => {
        response.items.forEach((image) => {
          if (isLoading) {
            getDownloadURL(image).then((url) => {
              setImageList((prev) => [...prev, url]);
            });
          }
        });
      });
    };
    listAllImages();
    return () => {
      setIsLoading(false);
    };
  }, []);

  const handleChange = (e) => {
    e.preventDefault();
    for (let i = 0; i < e.target.files.length; i++) {
      const newImage = e.target.files[i];

      newImage["id"] = Math.random();
      setImages((prevState) => [...prevState, newImage]);
    }
  };

  return (
    <div className="docs">
      <>
        {images.length > 0 && (
          <h3 className="common__Title margin__Top margin__Left">
            {images.length} files selected
          </h3>
        )}
        <div className="docs__Images__Upload__List margin__Top">
          {images.length > 0 &&
            images.map((image) => (
              <div className="docs__Image__Upload">
                <AiFillCloseCircle
                  onClick={() =>
                    handleRemoveSelectedItem(image, images, setImages)
                  }
                  className="top__Extreme__Right h5__Color"
                />
                <h5 className="h5__Font h5__Color" key={image.name}>
                  {image.name}
                </h5>
              </div>
            ))}
        </div>
        <div className="common__Header">
          <label htmlFor="file" className="docs__Header margin__Left">
            <ImFilesEmpty className="camera common__Title" />

            <input
              multiple
              className="file"
              onChange={handleChange}
              type="file"
              id="file"
            />
            <h3 className="common__Title">Add file</h3>
          </label>
          <Button
            onClick={() =>
              handleUpload(
                images,
                projectToggleData,
                setImages,
                signedInUser.uid
              )
            }
            value="Upload"
            styleClass="common__Button margin__Left"
          />
        </div>
      </>

      <div className="docs__Main">
        {imageList.length > 0 ? (
          imageList.map((image) => (
            <div
              key={image}
              className="docs__Files"
              // style={{
              // backgroundImage: `url(${image})`,
              //   backgroundSize: "contain",
              //   backgroundRepeat: "no-repeat",
              //   minWidth: "180xp",
              //   maxWidth: "100%",
              //   padding: "10px",
              //   position: "relative",
              //   marginTop: "15px",
              // }}
            >
              <MdOutlineClose
                className="top__Left common__Title"
                onClick={() => handleDeleteFile(image)}
              />
              <BsThreeDots className="top__Extreme__Right common__Title" />

              <iframe
                width="90%"
                height="170"
                src={image}
                style={{
                  border: "none",
                  overflow: "hidden",
                  objectFit: "contain",
                  justifyContent: "center",
                  padding: "10px",
                  overflowY: "hidden",
                }}
                scrolling="no"
              />
            </div>
          ))
        ) : (
          <img src={LoadingSvg} className="docs__Center" />
        )}
      </div>
    </div>
  );
}

export default Docs;
