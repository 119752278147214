import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { collection, getDocs } from "firebase/firestore";
import { db } from "../../components/firebase";

const initialState = {
  teams: [],
  loading: false,
};

export const getTeams = createAsyncThunk("teams/getTeams", async () => {
  const teamRef = collection(db, "teams");
  const teamsList = await getDocs(teamRef);
  const teamsData = teamsList.docs.map((doc) => ({
    ...doc.data(),
    id: doc.id,
  }));

  return teamsData;
});
export const teamsSlice = createSlice({
  name: "teams",
  initialState,
  reducers: {},
  extraReducers: {
    [getTeams.pending]: (state) => {
      state.loading = true;
    },
    [getTeams.fulfilled]: (state, { payload }) => {
      state.loading = false;
      state.teams = payload;
    },
    [getTeams.rejected]: (state) => {
      state.loading = false;
    },
  },
});

export const selectTeams = (state) => state.teams.teams;

export default teamsSlice.reducer;
