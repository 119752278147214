import { useEffect, useState } from "react";
import "./Schedule.css";
import { deleteDoc, doc, updateDoc } from "firebase/firestore";
import { db } from "../firebase";
import Button from "../../libs/Button";
import { SiPagespeedinsights } from "react-icons/si";

import { AiOutlineExpand } from "react-icons/ai";
import { useResource } from "../../hooks/useResource";

import { selectUser } from "../../app/slices/userSlice";
import { useSelector } from "react-redux";

function Schedule() {
  const [actions, setActions] = useState(false);
  const [id1, setId1] = useState(null);
  const [modal, setModal] = useState(false);
  const [open, setOpen] = useState(false);
  const [expired, setExpired] = useState([]);

  const [openBox, setOpenBox] = useState(true);

  const [modalName, setModalName] = useState("");
  const [modalDate, setModalDate] = useState("");
  const [modalTime, setModalTime] = useState("");

  // List All Schedule

  const user = useSelector(selectUser);
  const scheduleData2 = useResource(process.env.REACT_APP_SCHEDULE);
  const scheduleData = scheduleData2.filter(
    (item) => item.createdBy === user.displayName
  );
  const handleShow = (id, data) => () => {
    setId1(id);

    console.log(`You selected ${id}`, data);

    setActions(!actions);
  };

  //   Handle Modal
  const modalOpen = async (id, data) => {
    setModal(true);
    setModalName(data.name);

    setModalDate(data.dates);
    setModalTime(data.times);
    console.log(`You are now in ${id}`, data.name);
  };

  const handleSchedule = async (id, data) => {
    // e.preventDefault();

    const scheduleDoc = doc(db, "schedule", id);
    const updateData = {
      dates: modalDate,
      times: modalTime,
    };

    await updateDoc(scheduleDoc, updateData);

    setModal(false);
    // getSchedule();
  };

  const sortSchedule = scheduleData.sort(
    (a, b) => new Date(a.dates).getTime() - new Date(b.dates).getTime()
  );

  const deleteSchedule = async (id) => {
    const scheduleDoc = doc(db, "schedule", id);

    // setActions(!actions);
    return await deleteDoc(scheduleDoc);
  };

  // Show Insight For a Project that passed scheduled Date

  const scheduleInsight = () => {
    setOpen(true);
    const sortSchedule = scheduleData.filter(
      (data) => new Date(data.dates).getTime() < new Date().getTime()
    );
    console.log(sortSchedule);

    setExpired(sortSchedule);
  };

  // console.log(scheduleInsight());

  // console.log(expired);

  return (
    <>
      {open && (
        <div className="create__ChatModal scale-up-ver-center schedule__Insights">
          You have
          {expired?.map((data) => (
            <div key={data?.id}>
              <h2 className="h2__Title">{data?.name}</h2>
            </div>
          ))}
          insights
        </div>
      )}
      <div className="flex">
        <h2 className="medium__Fonts">
          You have ({sortSchedule?.length}) upcoming Meetings
        </h2>
        <SiPagespeedinsights className="h2__Title" onClick={scheduleInsight} />
      </div>

      {openBox && (
        <div className="scheduleBoxes">
          <AiOutlineExpand
            className="top-right h2__Title"
            onClick={() => setOpenBox(!openBox)}
          />
          {sortSchedule.length > 0 &&
            sortSchedule.slice(0, 4).map((data) => (
              <div
                key={data.id}
                className="scheduleBox"
                style={{
                  backgroundColor: `${
                    new Date(data?.dates).getTime() > new Date().getTime()
                      ? "#947df3"
                      : "#fb275d"
                  }
  
                `,
                }}
              >
                <h5 className="h5__Font h5__Color">{data.name}</h5>
                <h5 className="h5__Font">{data.dates}</h5>
              </div>
            ))}
        </div>
      )}
      {!openBox && (
        <div className="schedule__Tasks scale-up-ver-center">
          {sortSchedule.length > 0 ? (
            sortSchedule.map((data) => (
              <div
                key={data.id}
                className="schedule__Task"
                style={{
                  backgroundColor: `${
                    new Date(data?.dates).getTime() > new Date().getTime()
                      ? "#947df3"
                      : "#fb275d"
                  }
    
                  `,
                }}
              >
                <h4 className="verySmall__Fonts">{data.name}</h4>
                <h5 className="verySmall__Fonts">
                  {data.dates} at
                  {data.times.slice(0, 9)}
                </h5>

                <h5
                  style={{ cursor: "pointer" }}
                  className="verySmall__Fonts schedule__OpsButton"
                  onClick={handleShow(data.id, data)}
                >
                  More
                </h5>
                {id1 === data.id && actions && (
                  <div className="schedule__Operations">
                    <Button
                      styleClass="medium__Button cancel"
                      // onClick={() => handleCancel(data.id)}
                      onClick={() => setActions(false)}
                      value="Cancel"
                    />
                    <Button
                      styleClass="medium__Button reschedule"
                      value="Reschedule"
                      onClick={() => modalOpen(data.id, data)}
                    />
                    <Button
                      styleClass="medium__Button delete"
                      value="Delete"
                      onClick={() => deleteSchedule(data.id, data)}
                    />
                  </div>
                )}

                {modal && data.id === id1 && (
                  <div className="schedule__Modal" key={data.id}>
                    <h4 className="small__Fonts cancel">Reschedule meeting</h4>
                    <div className="schedule__Input">
                      <h5 className="h5__Font">Name</h5>
                      <h5 className="verySmall__Fonts">{modalName}</h5>
                    </div>

                    <div className="schedule__Input">
                      <h5 className="h5__Font">Date</h5>
                      <input
                        className="schedule__InputText verySmall__Fonts"
                        type="text"
                        value={modalDate}
                        onChange={(e) => setModalDate(e.target.value)}
                      />
                    </div>
                    <div className="schedule__Input">
                      <h5 className="h5__Font">Time</h5>
                      <input
                        className="verySmall__Fonts"
                        type="text"
                        value={modalTime.slice(0, 9)}
                        onChange={(e) => setModalTime(e.target.value)}
                      />
                    </div>
                    <div className="schedule__Input">
                      <Button
                        styleClass="medium__Button"
                        value="Change"
                        onClick={() => handleSchedule(data.id, data)}
                      />

                      <Button
                        onClick={() => setModal(false)}
                        styleClass="medium__Button cancel"
                        value="Cancel"
                      />
                    </div>
                  </div>
                )}
              </div>
            ))
          ) : (
            <h3 className="small__Fonts h5__Color">
              Your schedule shows us here
            </h3>
          )}
        </div>
      )}
    </>
  );
}

export default Schedule;
