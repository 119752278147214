import { useState, useEffect } from "react";
import Button from "../../libs/Button";
import "./Chats.css";
import DropDown from "./DropDown";
import CreateChat from "./CreateChat";

import { IoMdAttach } from "react-icons/io";

import ChatDataService from "../../libs/crud";
import { useDispatch, useSelector } from "react-redux";
import { setChat } from "../../app/slices/chatSlice";

import { addDoc, collection, onSnapshot, query } from "firebase/firestore";
import { db, storage } from "../firebase";
import { selectchatName, selectchatId } from "../../app/slices/chatSlice";
import { selectUser } from "../../app/slices/userSlice";
import ResourceService from "../../libs/resource";
import Messages from "./Messages";
// Import Modal
import Header from "../Header";
import Modal from "../../libs/Modal";
import { getDownloadURL, ref, uploadBytesResumable } from "firebase/storage";
import { ReactToolTipData } from "../../libs/ReactToolTipData";

function Chats({ multiSelect = false }) {
  const dispatch = useDispatch();
  const [chats, setChats] = useState([]);
  const [input, setInput] = useState("");
  const chatId =
    useSelector(selectchatId) || JSON.parse(localStorage.getItem("chatId"));
  const chatName =
    useSelector(selectchatName) || JSON.parse(localStorage.getItem("chatName"));
  const user = useSelector(selectUser);
  const [messages, setMessages] = useState([]);
  const [userSelectionModal, setUserSelectionModal] = useState(false);
  const [urls, setUrls] = useState("");

  useEffect(() => {
    getChats();
    if (chatId !== null) {
      selectedGroupChat();
    }
  }, [chatId]);

  const getChats = async () => {
    const chatData = await ChatDataService.getAllChats("chats");
    setChats(
      chatData.docs.map((doc) => ({
        ...doc.data(),
        id: doc.id,
      }))
    );
    if (chatId !== null) {
      localStorage.setItem("chatId", JSON.stringify(chatId));
      localStorage.setItem("chatName", JSON.stringify(chatName));
    }

    // console.log(chats);
  };

  const handleClick = async (data) => {
    console.log(data);

    dispatch(
      setChat({
        chatId: data.id,
        chatName: data.name,
        allowedUsers: data.users,
      })
    );
  };

  const createChatMessage = async (e) => {
    e.preventDefault();
    if (input.length === "") return;
    await addDoc(
      collection(
        db,
        process.env.REACT_APP_CHATS,
        chatId,
        process.env.REACT_APP_CHATMESSAGES
      ),
      {
        input: input,
        name: user?.displayName,
        email: user?.email,
        createdAt: new Date().getTime(),
        photo: user?.photo,
      }
    );

    setInput("");
  };

  // console.log(db);
  // Get Selected Group Chat

  const selectedGroupChat = async (e) => {
    if (chatId === null) return;
    const msgRef = collection(
      db,
      process.env.REACT_APP_CHATS,
      chatId,
      process.env.REACT_APP_CHATMESSAGES
    );
    const q = query(msgRef);
    onSnapshot(q, (querySnapshot) => {
      let msgs = [];
      querySnapshot.forEach((doc) => {
        msgs.push(doc.data());
      });
      setMessages(msgs);
    });
  };

  const sortedChat = () => {
    return messages.sort((a, b) => a.createdAt - b.createdAt);
  };

  // Upload Image

  const handleUpload = (e) => {
    e.preventDefault();
    const file = e.target.files[0];
    uploadFiles(file);
  };

  const uploadFiles = async (file) => {
    if (!file) return;

    const storageRef = ref(storage, `${chatName}/${file.name}`);
    const uploadTask = uploadBytesResumable(storageRef, file);
    uploadTask.on(
      "state_changed",
      (snapshot) => {
        const prog = Math.round(
          (snapshot.bytesTransferred / snapshot.totalBytes) * 100
        );
      },
      (error) => console.log(error),
      () => {
        getDownloadURL(uploadTask.snapshot.ref).then(async (downloadURL) => {
          await ResourceService.createNestedResource(
            process.env.REACT_APP_CHATS,
            chatId,
            process.env.REACT_APP_CHATMESSAGES,
            {
              urls: downloadURL,
              name: user?.displayName,
              email: user?.email,
              createdAt: new Date(),
              photo: user?.photo,
            }
          );
        });
      }
    );
  };

  return (
    <>
      {/* <Header /> */}
      {userSelectionModal && (
        <Modal
          title="Add Users"
          setUserSelectionModal={setUserSelectionModal}
          multiSelect
          chatId={chatId}
        />
      )}
      <div className="chats__Header">
        <h3 className="h2__Title margin__Left">Connect with Team</h3>

        <CreateChat />
      </div>
      <div className="chats__DropDown">
        <DropDown
          items={chats}
          title={
            chatName !== null
              ? chatName
              : `Select Group Total Groups ${chats.length}`
          }
          onClick={(data) => handleClick(data)}
        />
        {chatName !== null ? (
          <Button
            value="Add User"
            onClick={() => setUserSelectionModal(!userSelectionModal)}
            styleClass="common__Button margin__Left"
          >
            Add Users
          </Button>
        ) : (
          ""
        )}
      </div>

      <div className="chats" id="chats">
        <h2 className="h2__Title">Showing {messages?.length} conversations</h2>
        <div className="h5__Color h5__Font overflow-hidden">
          {sortedChat().length > 0 ? (
            sortedChat().map((data, index) => (
              <Messages contents={data} key={index} />
            ))
          ) : (
            <h5 className="h5__Font">
              💡Chats will appear once you select group
            </h5>
          )}
        </div>
        <form className="chat__Fields chat__Form" onSubmit={createChatMessage}>
          <input
            type="text"
            autoFocus
            value={input}
            onChange={(e) => setInput(e.target.value)}
            className="chat__Input bottom-height"
            placeholder="Start Chat"
          />
          <button onClick={createChatMessage}>Send</button>

          <div className="addImage">
            <label htmlFor="file">
              <IoMdAttach className="image__Button" />
            </label>
            <input
              // multiple
              className="file"
              onChange={handleUpload}
              type="file"
              id="file"
            />
          </div>
        </form>
      </div>
    </>
  );
}

export default Chats;
