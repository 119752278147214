import { useState } from "react";
import style from "./EditSignleProject.module.css";
import Button from "./Button";
import { AiOutlineNotification } from "react-icons/ai";
import { toast } from "react-toastify";
import ResourceService from "./resource";
import StatusButton from "./StatusButton";

function EditSingleProject({ selectedProject }) {
  const [projectName, setProjectName] = useState(`${selectedProject.name}`);
  const [projectPriority, setProjectPriority] = useState(
    `${selectedProject.priority}`
  );
  const [projectTags, setProjectTags] = useState(`${selectedProject.tags}`);

  const updateData = {
    name: projectName,
    priority: projectPriority,
    tags: projectTags,
  };

  const editProjectData = () => {
    try {
      ResourceService.updateResource(
        selectedProject.id,
        updateData,
        process.env.REACT_APP_PROJECTS
      );

      const notify = () =>
        toast.success(`${selectedProject.name} updated successfully`);
      notify();
    } catch (err) {
      const notify = () =>
        toast.error(`Error updating ${selectedProject.name}`);
      notify();
    } finally {
      setProjectTags("");
      setProjectName("");
      setProjectPriority(0);
    }
  };

  return (
    <div className="input">
      <h2 className="h2__Title">Editing {selectedProject.name}</h2>
      <div className="border__Bottom margin__Top"></div>
      <div className={style.input}>
        <label className={style.input__Lable} htmlFor="">
          Name
        </label>
        <input
          className="common__Input"
          autoFocus
          type="text"
          value={projectName}
          onChange={(e) => setProjectName(e.target.value)}
        />
      </div>
      <div className={style.input}>
        <label className={style.input__Lable} htmlFor="">
          Priority
        </label>
        <input
          className="common__Input"
          type="number"
          value={projectPriority}
          onChange={(e) => setProjectPriority(e.target.value)}
        />
      </div>
      <div className={style.input}>
        <label className={style.input__Lable} htmlFor="">
          Tags
        </label>
        <input
          className="common__Input"
          type="text"
          value={projectTags}
          onChange={(e) => setProjectTags(e.target.value)}
        />
      </div>
      <Button
        styleClass="margin__Top common__Button"
        onClick={editProjectData}
        value="Update"
      />

      {selectedProject.status !== "Done" && (
        <>
          <h2 className="h2__Title margin__Top">
            Create an alert for this project
          </h2>
          <div className="border__Bottom margin__Top"></div>
          <div className="common__Traditional__Flex">
            <>
              <AiOutlineNotification className="h2__Title cursor__Pointer" />

              <div className=" notification__Alert__Create h2__Title  margin__Left margin__Top danger__Color">
                Notify me when status changed from
                <h5 className="h5__Color common__Border__Bottom cursor__Pointer">
                  {selectedProject.status}
                </h5>
                to
                <StatusButton
                  status={selectedProject.status}
                  id={selectedProject.id}
                  desiredStatus={selectedProject.desiredStatus}
                />
              </div>
            </>
          </div>
        </>
      )}
    </div>
  );
}

export default EditSingleProject;
