import Header from "./Header";
import "./Blogs.css";
import Footer from "./Footer";
import { useResource } from "../hooks/useResource";

function Blogs() {
  const blogsData = useResource(process.env.REACT_APP_BLOGS);

  return (
    <>
      <div className="blogs">
        <Header />
        <h2 className="h2__Title">Checkout our latest blogs</h2>

        <div className="blogs__Main">
          {blogsData.map((blog) => (
            <div className="blogs__Card" key={blog.id}>
              <h2 className="h2__Title blogs__Title">{blog.name}</h2>
              <img src={blog.urls} alt={blog.name} className="blogs__Image" />
            </div>
          ))}
        </div>
      </div>
      <Footer />
    </>
  );
}

export default Blogs;
