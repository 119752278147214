import { configureStore } from "@reduxjs/toolkit";
import userReducer from "./slices/userSlice";
import postReducer from "./slices/projectSlice";
import usersReducer from "./slices/usersSlice";
import chatReducer from "./slices/chatSlice";
import teamReducer from "./slices/teamSlice";
import projectToggleReducer from "./slices/projectToggleSlice";
export default configureStore({
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      serializableCheck: {
        // Ignore these action types
        ignoredActions: [
          "posts/getPosts/pending",
          "posts/getPosts/fulfilled",
          "reminders/getReminders/pending",
          "reminders/getReminders/fulfilled",
          "reminders/getReminders/rejected",
        ],

        // Ignore these field paths in all actions
        ignoredActionPaths: ["meta.arg", "payload.dates"],
        // Ignore these paths in the state
        ignoredPaths: ["data2.dates"],
      },
    }),
  reducer: {
    user: userReducer,
    projects: postReducer,
    users: usersReducer,
    chat: chatReducer,
    teams: teamReducer,
    projectToggle: projectToggleReducer,
  },
});
