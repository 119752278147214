import ProjectsTable from "../../../libs/ProjectsTable";

function ProjectsDataTable({ data, handleChange }) {
  return (
    <div className="taskCard">
      <div className="div1">
        <h3 className="small__Fonts project__TaskTitle">Task</h3>
        <h4 className="verySmall__Fonts">Status</h4>
        <h4 className="verySmall__Fonts">Timeline</h4>
        <h4 className="verySmall__Fonts">Prority (/10)</h4>
        <h4 className="verySmall__Fonts">Tags</h4>
        <h4 className="verySmall__Fonts">Owner</h4>
      </div>

      {data?.length > 0 &&
        data?.map((data) => (
          <ProjectsTable
            key={data.id}
            data={data}
            handleChange={handleChange}
          />
        ))}
    </div>
  );
}

export default ProjectsDataTable;
