import { collection, onSnapshot } from "firebase/firestore";
import { useState, useEffect } from "react";
import { db } from "../components/firebase";

export const useResource = (collectionName) => {
  const [resource, setResource] = useState([]);

  useEffect(() => {
    let isMounted = true; // note mutable flag

    const resourceData = collection(db, collectionName);
    onSnapshot(resourceData, (doc) => {
      if (isMounted) {
        setResource(
          doc.docs.map((teamd) => ({ ...teamd.data(), id: teamd.id }))
        );
      }
    });

    return () => {
      isMounted = false;
    };
  }, [resource]);

  return resource;
};
