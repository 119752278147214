import React from "react";
import Button from "../../libs/Button";
import "./Search.css";
function Search({ searchTerm, setSearchTerm }) {
  return (
    <div className="search">
      <input
        type="text"
        value={searchTerm}
        onChange={(e) => setSearchTerm(e.target.value)}
        placeholder="Search Project"
        className="search-field"
      />

      <Button className="button__Outlined" value="Search Term" />
    </div>
  );
}

export default Search;
