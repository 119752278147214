import { doc, updateDoc } from "firebase/firestore";
import { db } from "../../firebase";
import ResourceService from "../../../libs/resource";
import { toast } from "react-toastify";

export const handleAssignTeam = async (
  data,
  taskId,
  selectedProject,
  toggleModal
) => {
  const assignTeam = doc(db, "projects", taskId);
  try {
    await updateDoc(assignTeam, {
      team: data.name,
      teamId: data.id,
    }).then(async () => {
      const updateTeamWithTaskId = doc(db, "teams", data.id);
      await updateDoc(updateTeamWithTaskId, {
        projectId: taskId,
        assigned: !data.assigned,
        projectName: selectedProject.name,
      });
    });
    toast.success(`Team ${data.name} added`);

    toggleModal();
  } catch {
    toast.warn(`Error adding team ${data.name}`);
  }
};

export const deleteProject = async (data, toggleModalOpen) => {
  try {
    toggleModalOpen();
    if (data.status !== "Done") {
      return toast.warn(`Project ${data.name} is not completed`);
    }
    await ResourceService.deleteResource(
      process.env.REACT_APP_PROJECTS,
      data.id
    );

    const notify = () => toast.success(`${data.name} deleted`);
    notify();
  } catch (err) {
    toast.warn(`${err.message}`);
  }
};
