import { useEffect, useState } from "react";
import "./HalfPageModal.css";
import { useFormInput } from "../hooks/useFormInput";
import ResourceService from "../libs/resource";
import { selectUser } from "../app/slices/userSlice";
import { useSelector } from "react-redux";
import moment from "moment";
import "../css/common.css";
import { collection, onSnapshot, query } from "firebase/firestore";
import { db } from "../components/firebase";
import { RiTimerLine } from "react-icons/ri";
import NoUpdates from "../components/images/no_updates.svg";
import Comments from "./Comments";
import CommonBottom from "./CommonBottom";
import CommonHeader from "./CommonHeader";
import { selectProjectToggle } from "../app/slices/projectToggleSlice";
import ReUsableTabs from "../components/ReUsableTabs";
import Docs from "../components/Admin/Docs";
import ProjectMembers from "../components/Admin/ProjectMembers";
import CommonModal from "./CommonModal";
function HalfPageModal({ toggleProjectOpen }) {
  useEffect(() => {
    getProjectUpdates();
  }, []);

  const [inputData, resetInput] = useFormInput("");
  const [updates, setUpdates] = useState(false);
  const [id1, setId1] = useState(null);
  const [commentBox, setCommentBox] = useState(false);
  const [commentData, resetComment] = useFormInput("");

  const [showComments, setShowComments] = useState(false);

  const [selectedCommentId, setSelectedCommentId] = useState(false);
  const signedInUser = useSelector(selectUser);
  const updateProject = {
    inputData: inputData.value,
    createdBy: signedInUser.displayName,
    photo: signedInUser.photo,
    createdAt: new Date().getTime(),
  };

  // const storage = getStorage();
  // const imRef = ref(storage, "files/");
  // getMetadata(imRef).then((metadata) => console.log(metadata));
  const projectToggleData = useSelector(selectProjectToggle);
  // console.log(projectToggleData);
  const handleUpdate = async () => {
    await ResourceService.createNestedResource(
      process.env.REACT_APP_PROJECTS,
      projectToggleData.id,
      process.env.REACT_APP_PROJECT_UPDATES,
      updateProject
    );

    resetInput();
  };
  const msgRef = collection(
    db,
    process.env.REACT_APP_PROJECTS,
    projectToggleData.id,
    process.env.REACT_APP_PROJECT_UPDATES
  );
  const q = query(msgRef);
  const getProjectUpdates = () => {
    onSnapshot(q, (querySnapshot) => {
      let msgs = [];
      querySnapshot.forEach((doc) => {
        msgs.push({ ...doc.data(), id: doc.id });
      });
      setUpdates(msgs);
    });
  };

  const handleCommentBox = (id) => {
    setId1(id);
    setCommentBox(!commentBox);
  };

  const handleSelectedComment = (id) => {
    setSelectedCommentId(id);
    setShowComments(!showComments);
  };

  const addComment = async (item) => {
    setId1(item.id);

    await ResourceService.nestedUpdateResource(
      process.env.REACT_APP_PROJECTS,
      projectToggleData.id,
      process.env.REACT_APP_PROJECT_UPDATES,
      item.id,
      {
        userName: signedInUser.displayName,
        userImage: signedInUser.photo,
        userId: signedInUser.uid,
        createAt: new Date().getTime(),
        comment: commentData.value,
      }
    );
    resetComment();
    setCommentBox(!commentBox);
    console.log("Data updated");
  };

  return (
    <>
      <CommonModal
        styleClass="halfPageModal scale-in-hor-right"
        onClose={toggleProjectOpen}
      >
        <div key={projectToggleData.id}>
          <div className="common__HeaderContent">
            <img
              src={projectToggleData.photo}
              alt={projectToggleData.createdBy}
              className="top__Right profile"
            />
            <>
              <h2 className="common__Title">{projectToggleData?.name}</h2>
              <h2 className="common__Title common__Danger">
                ● {projectToggleData.status}
              </h2>
            </>
          </div>
          <ReUsableTabs
            tabs={[
              {
                title: "Updates",
                index: 0,
                render: () => (
                  <>
                    {/* Common Header */}
                    <CommonHeader
                      name={projectToggleData.name}
                      data={inputData}
                      onClick={handleUpdate}
                      cols="60"
                      styleClass="common__Header"
                      buttonName="Update"
                    />
                    {/* Ends */}
                    <div>
                      {updates.length > 0 ? (
                        updates?.map((item) => (
                          <div
                            className="common__Card margin__Bottom"
                            key={item.id}
                          >
                            <time className="common__Time top__Absolute__Right">
                              <RiTimerLine />
                              {moment(item?.createdAt).fromNow()}
                            </time>
                            <div className="common__Card__Left__Header">
                              <img
                                src={item?.photo}
                                alt={item?.id}
                                className="profile__Round margin__Right"
                              />
                              <h2 className="common__Title">
                                {item?.createdBy.toUpperCase()}
                              </h2>
                            </div>

                            <div className="halfPage__UpdateContent margin__Top">
                              <h2 className="verySmall__Fonts h5__Color">
                                {item?.inputData}
                              </h2>
                            </div>

                            {/* Common Bottom Component*/}
                            <CommonBottom
                              handleSelectedComment={handleSelectedComment}
                              handleCommentBox={handleCommentBox}
                              item={item}
                            />

                            {/* Common Bottom Component*/}

                            {commentBox && item.id === id1 && (
                              <CommonHeader
                                styleClass="common__Header halfPageModal__CommentBox scale-up-ver-center"
                                data={commentData}
                                cols="50"
                                onClick={() => addComment(item)}
                                setCommentBox={setCommentBox}
                                buttonName="Reply"
                                title="Write your comment ..."
                              />
                            )}
                            {showComments && item.id === selectedCommentId && (
                              <div className="halfPageModal__CommentBox1 scale-up-ver-center">
                                {item.comments?.length > 0 &&
                                  item.comments?.flatMap((commentData) => (
                                    <Comments
                                      commentData={commentData}
                                      setShowComments={setShowComments}
                                      showComments={showComments}
                                      key={commentData.id}
                                    />
                                  ))}
                              </div>
                            )}
                          </div>
                        ))
                      ) : (
                        <div className="common__Content">
                          <img
                            src={NoUpdates}
                            className="halfPageModal__Image"
                          />
                          <h2 className="h2__Title">
                            No updates for this project
                          </h2>
                        </div>
                      )}
                    </div>
                  </>
                ),
              },
              {
                title: "Docs",
                index: 1,

                render: () => <Docs projectToggleData={projectToggleData} />,
              },
              {
                title: "Members",
                index: 2,
                render: () => (
                  <ProjectMembers projectToggleData={projectToggleData} />
                ),
              },
            ]}
          />
        </div>
      </CommonModal>
    </>
  );
}

export default HalfPageModal;
