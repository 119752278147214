import "./About.css";
import Header from "./Header";
import Footer from "./Footer";
import Sticky from "../libs/Sticky";
import Image2 from "./images/Bizkey-Award2.jpeg";
import Image3 from "./images/Bizkey-Award3.jpeg";

function About() {
  return (
    <>
      <div className="about">
        <Header />

        <div
          className="about__Section"
          data-aos="fade-down"
          data-aos-easing="linear"
          data-aos-duration="1500"
        >
          <div className="about__About">
            <h2 className="h2__Title">About Us</h2>

            <div className="h5__Font about__Content">
              BizKey Consultancy award winnning Ahmedabad, Gujarat based ISO
              solution provider helping businesses to get certification,
              training, consultancy and implementation. We Focus on risk
              management and customer satisfaction which directly boosts
              revenues and reduce operational costs. Our state-of-the-art
              implementation methodology and certification process will give you
              the ultimate competitive advantage ISO 9001, ISO 14001, ISO 45001,
              ISO 22000, HACCP, GMP.FSSC BRC, SQF, Our services range from Gap
              Analysis, Internal Audit, Pre-Assessment, Document Review,
              Supplier Survey, Assist in third party Audit and Continuous
              Improvement is on our one of primary goal.
            </div>
          </div>
          <video
            controls="controls"
            className="about__Advertisement"
            allowFullScreen
            loading="eager"
            src={process.env.REACT_APP_ADVERTISEMENT}
          />
        </div>

        <div className="about__Audits">
          <div className="about__Online">
            <h3 className="h2__Title">Online Audits</h3>
            <div className="about__OnlinePrograms">
              <li>No need to hire on-site consultant</li>
              <li>Cost effective online Consulting Program®</li>
              <li>On demand training</li>
              <li>Get certified on given timeline</li>
              <li>Priority online support</li>
            </div>
            <div className="about__Services">
              <li>ISO 9001 </li> <li>ISO 14001</li>
              <li>ISO 45001</li>
              <li> ISO 22000/FSSC </li>
              <li>ISO 22000 /cGMP</li>
              <li>ISO HACCP</li>
              <li>ISO HALAL</li>
            </div>
          </div>
          <div className="about__Online">
            <h3 className="h2__Title">Offline Audits</h3>

            <div className="about__OnlinePrograms">
              <li>
                Best suited for companies who wants some hand-holding with an
                onsite-Off-site work share model where an expert can come onsite
                as needed
              </li>

              <div className="about__Services">
                <li> ISO 9001:2015</li>
                <li>ISO 14001:2015 </li>
                <li> ISO 45001:2018</li>
                <li> ISO 22000:2018</li>
                <li>FSSC 22000:2018</li>
                <li>NABL</li>
                <li>NABH</li>
                <li> GMP</li>
                <li> BRC</li>
                <li> HACCP</li>
                <li> Kosher</li>
                <li>Halal in the most Effective, Economical</li>

                <li>Trademark(TM and Registration)</li>

                <li>APEDA Certificate</li>
                <li> Import-export licence(IEC) and GPCB.</li>
              </div>
            </div>
          </div>
        </div>
        <Sticky />
      </div>
      <div className="about__Awards">
        <h3 className="h2__Title">Awards and Recognition</h3>
        <div className="about__AwardsContent">
          <div className="about__AwardsVideo">
            <div className="about__AwardsVideoContent">
              <h3 className="gradient__text">
                Awarded on "The AEONIAN 2021" for "The best startup service"
              </h3>
            </div>
            <video
              controls="controls"
              className="about__Advertisement"
              allowFullScreen
              loading="eager"
              src={process.env.REACT_APP_BIZKEY_AWARD}
            />
          </div>
        </div>
      </div>
      <div className="about__Awards__Images">
        <img className="about__Awards__Image" src={Image2} />

        <img className="about__Awards__Image" src={Image3} />
      </div>

      <Footer />
    </>
  );
}

export default About;
