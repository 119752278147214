import { useState, useEffect } from "react";
import CommonModal from "../../../libs/CommonModal";
import { query, where, getDocs, collection } from "firebase/firestore";
import { db } from "../../firebase";
import { toast } from "react-toastify";
import Button from "../../../libs/Button";

function QuickSearch({ handleInsights }) {
  const [email, setEmail] = useState("");
  const [searchData, setSearchData] = useState([]);

  useEffect(() => {
    let isMounted = true;

    if (isMounted) {
      getUserByEmail();
    }

    return () => {
      isMounted = false;
    };
  }, []);
  const handleSearchDataClose = () => {
    handleInsights();
    setEmail("");
    setSearchData("");
  };
  const getUserByEmail = async () => {
    if (!email) return;
    try {
      const userName = query(
        collection(db, "projects"),
        where("createdBy", "==", `${email}`)
      );
      const querySnapshot = await getDocs(userName);
      const getUsersData = querySnapshot.docs.map((doc) => ({
        ...doc.data(),
        id: doc.id,
      }));
      setSearchData(getUsersData);
    } catch (err) {
      const notify = () => toast.warn(`No data exist for ${email}`);
      notify();
    }
  };

  return (
    <CommonModal
      styleClass="sacale-up-ver-center modals__Project"
      onClose={handleSearchDataClose}
    >
      <div className="projects__GetUser__By__Email">
        <input
          type="text"
          className="common__Title no__Back "
          value={email}
          onChange={(e) => setEmail(e.target.value)}
          placeholder="Search User/ Projects...."
          autoFocus
        />
        <Button
          styleClass="common__Button"
          value="Get User By Email"
          onClick={getUserByEmail}
        />
      </div>

      <div className="border__Bottom margin__Top"></div>
      <div className="div1">
        <thead className="common__Title margin__Left">Status</thead>
        <thead className="common__Title margin__Left">Found in</thead>
        <thead className="common__Title margin__Left">Priority</thead>
      </div>

      {searchData.length > 0 &&
        searchData?.map((userData) => (
          <div className="div1">
            <tbody className="common__Title margin__Left">
              <h5 className="h5__Color h5__Font">{userData.name} </h5>
            </tbody>
            <tbody className="common__Title margin__Left">
              {userData.status}
            </tbody>
            <tbody className="common__Title margin__Left">
              {userData.priority}
            </tbody>
          </div>
        ))}
    </CommonModal>
  );
}

export default QuickSearch;
