import "./Footer.css";
import Logo from "../components/images/finalLogo.svg";
import NavbarLinks from "../libs/NavbarLinks";
import { NavLink } from "react-router-dom";

function Footer() {
  return (
    <div className="footer">
      <div className="footer__Contact">
        <NavLink to="/">
          <img className="header__Logo footer__Logo" src={Logo} alt="Logo" />
        </NavLink>

        <h3 className="footer__CEO">
          Ms. VANI MEHTA
          <br /> (Owner / ISO Consulting Director)
        </h3>
        <h4 className="footer__CEOContact">
          Mobile- +91-(898) 077-8631 <br />
          Email: md.bizkey@gmail.com
        </h4>
      </div>

      <div className="footer__Links">
        <NavbarLinks to="/about" value="About" />
        <NavbarLinks to="/blogs" value="Blogs" />
        <NavbarLinks to="/feedback" value="Feedback" />
      </div>
    </div>
  );
}

export default Footer;
