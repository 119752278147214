import { deleteDoc, doc, runTransaction } from "firebase/firestore";
import { useEffect, useState } from "react";
import { db } from "../firebase";
import { AiFillDelete, AiOutlineTeam } from "react-icons/ai";
import { ToastContainer, toast } from "react-toastify";
import { getUsers, selectUsers } from "../../app/slices/usersSlice";
import "./ListTeam.css";
import Button from "../../libs/Button";
import { useDispatch, useSelector } from "react-redux";
import { useResource } from "../../hooks/useResource";
import ResourceService from "../../libs/resource";
import { GrDetach } from "react-icons/gr";
import { detachFromProject } from "../../libs/commonFunctions";
function ListTeam() {
  const [open, setOpen] = useState(false);
  const [deleted, setDeleted] = useState(false);
  const dispatch = useDispatch();
  const [error, setError] = useState(false);
  useEffect(() => {
    dispatch(getUsers());
  }, []);

  const usersList = useSelector(selectUsers);

  const listTeamData = useResource(process.env.REACT_APP_TEAMS);

  const deleteTeam = async (data) => {
    const commonUsers = usersList.filter((o1) =>
      data.users?.some((o2) => o1.id === o2.id)
    );

    console.log("Common users:", commonUsers);

    try {
      await ResourceService.deleteResource("teams", data.id).then(() =>
        commonUsers.forEach(async (userData) => {
          const useRef = doc(db, "users", `${userData.id}`);

          await runTransaction(db, async (transaction) => {
            const sfDoc = await transaction.get(useRef);
            if (!sfDoc.exists()) {
              throw "Document does not exist!";
            }

            if (!error) {
              const newPopulation = sfDoc.data().workingOn - 1;

              transaction.update(useRef, { workingOn: newPopulation });
            }
          });

          console.log("Transaction successfully committed!");
        })
      );

      const notify = () => toast.success("Project deleted successfully!");
      notify();
    } catch {
      setDeleted(false);
      const notify = () => toast.warn("Failed To Delete");
      notify();

      console.log("Not authorized");
    }
  };

  return (
    <>
      {!open ? (
        <Button
          value="View Teams"
          styleClass="listItem__Button"
          onClick={() => {
            // fetchTeams();
            setOpen(!open);
          }}
        />
      ) : (
        <Button
          value="Hide Teams"
          styleClass="listItem__Button"
          onClick={() => {
            setOpen(!open);
          }}
        />
      )}

      {open && (
        <>
          <div className="listTeam">
            <ToastContainer
              astContainer
              position="top-right"
              autoClose={5000}
              hideProgressBar={false}
              newestOnTop={false}
              closeOnClick
              rtl={false}
              pauseOnFocusLoss
              draggable
              pauseOnHover
            />

            <h2 className="listTeam__Title">Teams</h2>
            <div className="listTeam__Design"></div>
            <div className="listTeam__Main">
              {listTeamData?.map((data) => (
                <div key={data.id} className="listTeam__Grid">
                  <h3 className="verySmall__Fonts top-right danger__Color">
                    By {data.createdBy}
                  </h3>
                  <h2 className="h5__Font">{data.name}</h2>
                  <div className="listTeam__InnerContent">
                    {data.assigned === false ? (
                      <h6 className="danger__Color h5__Font">
                        Not Assigned To Project Yet
                      </h6>
                    ) : (
                      <h4 className="medium__Fonts">
                        Working on {data?.projectName}
                      </h4>
                    )}
                    <div className="flex stick__Bottom">
                      <AiOutlineTeam className="tabs__Icons" alt="people" />
                      {data.users.map((user, index) => (
                        <img
                          key={index}
                          src={user.photoURL}
                          className="message__photo"
                        />
                      ))}
                    </div>

                    <AiFillDelete
                      className="bottom__Right medium__Fonts1"
                      onClick={() => deleteTeam(data)}
                    />

                    <GrDetach onClick={() => detachFromProject(data)} />
                  </div>
                </div>
              ))}
            </div>
          </div>
        </>
      )}
    </>
  );
}

export default ListTeam;
