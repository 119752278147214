import { useState } from "react";
import Papa from "papaparse";
import "./BatchJobs.css";
import Button from "../../libs/Button";
import axios from "axios";
function BatchJobs({ handleBatchOpen }) {
  const [files, setFiles] = useState([]);
  const [collectionId, setCollectionId] = useState("");
  const handleFile = (evt) => {
    const file = evt.target.files[0];
    console.log(file.type);

    if (file.type === "text/csv") {
      Papa.parse(file, {
        header: true,
        dynamicTyping: true,
        complete: (result) =>
          setFiles(result.data.slice(0, result.data.length - 1)),
      });
    } else {
      console.log("Upload CSV File Please");
    }
  };

  const startBatch = async () => {
    await axios.post(
      // "http://localhost:8080/batchJobs",
      "https://us-central1-bizkey-solution.cloudfunctions.net/batchJobs/batchJobs",
      // "https://hello-world-1-atqnqswzpq-uc.a.run.app/batchJobs",
      {
        files: files,
        collectionId: collectionId,
      }
    );
  };

  return (
    <div className="create__ChatModal scale-up-ver-center batchJobs">
      <h2 className="h2__Title orangeRed">Create Batch Jobs for CSV Files</h2>
      <input
        type="text"
        className="contact__Name"
        placeholder="Enter Collection Name"
        value={collectionId}
        onChange={(e) => setCollectionId(e.target.value)}
      />
      {files.length > 0 && (
        <div className="scale-in-hor-left alert">
          Great !! CSV file converted to JSON format having {files.length}
          entries in it
        </div>
      )}
      <input
        type="file"
        accept="*.csv"
        onChange={(files) => handleFile(files)}
      />
      <div className="modals__Buttons">
        <Button value="Start Batch" onClick={() => startBatch()} />
        <Button
          onClick={handleBatchOpen}
          value="Cancel"
          styleClass="feature__Danger__Btn"
        />
      </div>
    </div>
  );
}

export default BatchJobs;
