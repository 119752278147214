import { useState } from "react";
import "./DropDown.css";

import { RiCloseCircleLine } from "react-icons/ri";

import { IoIosArrowDropdown } from "react-icons/io";
import Button from "../../libs/Button";
import { db } from "../firebase";
import { arrayUnion, doc, updateDoc, arrayRemove } from "firebase/firestore";

function DropDown({
  items,
  multiSelect = false,
  // handleAssignment,
  title,
  taskId,
  onClick,
  buttonName,
  chatName,
}) {
  const [open, setOpen] = useState(false);
  const [selection, setSelection] = useState([]);
  const [modal, setModal] = useState(false);

  const toggle = () => setOpen(!open);

  const handleClose = () => {
    setModal(!modal);
    setOpen(!open);
  };
  const handleOnClick = async (item) => {
    // if (!selection.some((current) => current.id === item.id)) {
    //   if (!multiSelect) {
    //     setSelection([item]);
    //   } else if (multiSelect) {
    //     setSelection([...selection, item]);
    //   }
    // } else {
    //   let selectionAfterRemoval = selection;
    //   selectionAfterRemoval = selectionAfterRemoval.filter(
    //     (current) => current.id !== item.id
    //   );
    //   setSelection([...selectionAfterRemoval]);
    // }
    // const assignUser = doc(db, "projects", taskId);
    // await updateDoc(assignUser, {
    //   assign: arrayUnion({
    //     userName: item.name || "",
    //     userImage: item.photoURL || "",
    //     assigned: new Date() || "",
    //   }),
    // });

    console.log("You clicked on", item);
  };

  const deleteAllAssignMent = async (item) => {
    const assignUser = doc(db, "projects", taskId);

    await updateDoc(assignUser, {
      assign: arrayRemove([]),
    });
  };
  const itmeInSelection = (item) => {
    if (selection.find((current) => current.id === item.id)) {
      return true;
    }
    return false;
  };

  const uniqueUsers = items.filter((obj) => selection.indexOf(obj) == -1);

  // console.log("Unique Users are:", uniqueUsers);
  // console.log(items);
  return (
    <div className="dropDown">
      <div onClick={() => toggle(!open)} className="dropDown__Header">
        {selection?.length > 0 ? (
          selection?.map((data) => (
            <div key={data.id}>
              <img
                src={data.photoURL}
                alt={data.email}
                className="assign__Image"
              />
            </div>
          ))
        ) : (
          <h5 className="h5__Font">{title}</h5>
        )}
        <div className="dropDown__Close">
          {open ? <RiCloseCircleLine /> : <IoIosArrowDropdown />}
        </div>
      </div>

      {open && (
        <>
          <ul className="dropDown__Scroll">
            {items.map((item) => (
              <li
                className="dropDown__List"
                key={item.id}
                onClick={() => onClick(item)}
              >
                <div>
                  {item?.photoURL === null ? (
                    <img
                      onClick={() => handleOnClick(item)}
                      src={item.photoURL}
                      alt={item.email}
                      className="assign__Image"
                    />
                  ) : (
                    ""
                  )}

                  <span className="h5__Font">{item.name}</span>

                  <span>{itmeInSelection(item) && <RiCloseCircleLine />}</span>
                </div>
              </li>
            ))}
          </ul>
          <div className="schedule__Input">
            {buttonName && (
              <Button
                styleClass="verySmall__Button"
                value={buttonName}
                onClick={() => deleteAllAssignMent()}
              />
            )}
          </div>
        </>
      )}
    </div>
  );
}

export default DropDown;
