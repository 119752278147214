import CommonHeader from "../../libs/CommonHeader";
import "./ProjectMembers.css";
import ResourceService from "../../libs/resource";
import { useEffect, useState } from "react";
import { useFormInput } from "../../hooks/useFormInput";
import ReUsableUser from "../../libs/ReUsableUser";
import { AssignTeam } from "../../libs/AssignTeam";
import { useResource } from "../../hooks/useResource";

// import { handleAssignTeam } from "../../libs/commonFunctions";
function ProjectMembers({ projectToggleData }) {
  const [members, setMembers] = useState([]);
  const [inputData, resetInput] = useFormInput("");
  const teamsData = useResource(process.env.REACT_APP_TEAMS);

  useEffect(() => {
    let isMounted = true;
    const getData = async () => {
      const members = await ResourceService.getResource(
        projectToggleData.teamId,
        process.env.REACT_APP_TEAMS
      );
      if (isMounted) {
        setMembers(members.users);
        console.log(members.users);
      }
    };
    getData();
    return () => {
      isMounted = false;
    };
  }, []);

  const handleUpdate = () => {
    console.log("data to be update");

    resetInput();
  };

  return (
    <div className="projecMembers">
      <CommonHeader
        name={projectToggleData.name}
        data={inputData}
        onClick={handleUpdate}
        cols="10"
        styleClass="common__Header"
        buttonName="Add New User"
        title="Enter new user emailId"
      />

      {members.length > 0 ? (
        <>
          <h2 className="medium__Fonts">{projectToggleData?.teamName}</h2>
          <ReUsableUser
            resourceName={members}
            teamId={projectToggleData.teamId}
          />
        </>
      ) : (
        // <AssignTeam
        //   teamsData={teamsData}
        //   // handleAssignTeam={handleAssignTeam()}
        // />
        <h3>No Teams Selected</h3>
      )}
    </div>
  );
}

export default ProjectMembers;
