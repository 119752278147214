import axios from "axios";
import "./Contact.css";
import { MdOutlineClose } from "react-icons/md";
import { useFormInput } from "../hooks/useFormInput";
function Contact() {
  const [name, resetName] = useFormInput("");
  const [email, resetEmail] = useFormInput("");
  const [message, resetMessage] = useFormInput("");
  const [success, resetSuccess] = useFormInput(false);
  const [inquire, resetInquire] = useFormInput("");
  const handleClose = (e) => {
    e.preventDefault();

    resetSuccess(false);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    const response = await axios.post(
      "http://localhost:5001/bizkey-solution/us-central1/sendContactDetail/contact",
      // process.env.REACT_APP_CONTACT_URL,

      {
        name: name.value,
        email: email.value,
        message: message.value,
        inquire: inquire.value,
      }
    );

    resetName();
    resetEmail();
    resetMessage();
    resetSuccess(true);
    resetInquire();
  };

  return (
    <>
      {success.value ? (
        <div className="contact__Success">
          <h3 className="h5__Font h5__Color">
            Message: We received your message
          </h3>
          <br />
          <h5 className="h5__Font h5__Success">
            Next step💡 We sent a copy of a message and get back to you soon as
            possible! Thanks for choosing us !
          </h5>
          <MdOutlineClose
            onClick={handleClose}
            className="h2__Title about__Close"
          />
        </div>
      ) : (
        ""
      )}

      <div
        id="contact"
        className="contact"
        data-aos="fade-down"
        data-aos-easing="linear"
        data-aos-duration="1500"
      >
        <h2 className="h2__Title contact__Title">Get in touch !</h2>
        <h5 className="h5__Font h5__Color h5__Description">
          Contact us for a quote
        </h5>
        <div className="contact__MainDiv">
          <div className="contact__Page">
            <div className="contact__Mobile">
              <div className="contact__viaEmail">
                <i className="fas fa-phone-alt"></i>
                <h3 className="contact__viaEmailFont h2__Title">
                  +91-8980778631
                </h3>
              </div>
              <div className="contact__viaEmail">
                <i className="far fa-envelope"></i>
                <h3 className="contact__viaEmailFont h2__Title">
                  md.bizkey@gmail.com
                </h3>
              </div>
            </div>
            <form onSubmit={handleSubmit} className="contact__Form">
              <div className="contact__Info">
                <input
                  className="contact__Name"
                  type="text"
                  {...name}
                  placeholder="Enter your name"
                  required
                />
                <input
                  className="contact__Name"
                  {...email}
                  type="email"
                  placeholder="Enter email"
                  required
                />
                <select
                  className="contact__Name"
                  placeholder="Enter"
                  {...inquire}
                >
                  <option value="ISO">ISO Certification</option>
                  <option value="ISO-AWARENESS-TRAINING">
                    ISO AWARENESS TRAINING
                  </option>
                  <option value="INTERNAL-AUDIT">INTERNAL AUDIT</option>
                  <option value="SUPPLIER-AUDIT">SUPPLIER AUDIT</option>
                  <option value="FSSAI-LICENSE">FSSAI LICENSE</option>
                  <option value="GPCB-CONSULTING">GPCB CONSULTING</option>
                  <option value="IMPORT-EXPORT">IMPORT / EXPORT</option>
                  <option value="APEDA-CERTIFICATE">APEDA CERTIFICATE</option>
                  <option value="TRADEMARK">TRADEMARK</option>
                </select>
              </div>
              <div className="contact__Textarea">
                <label className="h5__Font" htmlFor="message">
                  Message (optional)
                </label>
                <textarea
                  className="contact__TextareaComponent"
                  placeholder="Enter your message or query"
                  rows="6"
                  {...message}
                  cols="50"
                ></textarea>
                <button onClick={handleSubmit} className="contact__Submit">
                  Submit
                </button>
              </div>
            </form>
          </div>
          <div className="contact__SocialLinks">
            <a href="https://www.facebook.com/Bizkey786">
              <i className="fab fa-facebook"></i>
            </a>
            <a href="https://www.linkedin.com/in/vani-mehta-82590667">
              <i className="fab fa-linkedin"></i>
            </a>
            <a href="https://www.instagram.com/vani_mehta31">
              <i className="fab fa-instagram"></i>
            </a>
          </div>
        </div>
      </div>
    </>
  );
}

export default Contact;
