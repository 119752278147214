import { useEffect, useState } from "react";
import "./Home.css";
import Header from "./Header";
import Strategy from "./Strategy";
import Contact from "./Contact";
import Solution from "./Solution";
import Footer from "./Footer";
import AosLib from "../libs/AosLib";
import Sticky from "../libs/Sticky";

function Home() {
  // useEffect(() => {
  //   const timer = setTimeout(() => console.log("Initial timeout!"), 3000);
  //   return () => clearTimeout(timer);
  // }, []);
  return (
    <>
      <div className="home">
        <Header />

        <div className="home__Main">
          <div className="home__Section">
            <h1 className="home__Title h2__Title h2__Margin">
              ISO Consultation & Training Provider
            </h1>

            <div className="home__Section1">
              <h2 className="h2__Margin h2__Size h2__Title">
                Get smart solutions for your business
              </h2>

              <h5 className="home__Color h5__Font">
                We are ISO Business Consult company based in Ahmedabad,Gujarat.
                We are dedicated to serving companies and individuals to find
                the various ISO certification in their respected firm.
              </h5>
            </div>
          </div>
          <div className="home__Statements">
            <div className="home__Mission">
              <h2 className="h2__Title home__MissionText">Our Mission</h2>
              <h5 className="h5__Font h5__Color">
                "Bizkey is a professional, enthusiastic and innovative team, to
                provide consultancy, certification, training and implementation
                services which will help firms to become more productive and
                profitable."
              </h5>
            </div>
            <div className="home__Mission">
              <h2 className="h2__Title home__MissionText">Our Vision</h2>
              <h5 className="h5__Font h5__Color">
                "We will undertake and deliver any project, regardless of size,
                which we pursue and to provide a differentiated offering
                resulting in value-for-value returns and our customer stay
                competitive."
              </h5>
            </div>
          </div>
          <Sticky />
        </div>
        <div className="home__Decorations">
          <AosLib value="Award winning" />

          <AosLib value=" 99.99% success rate" />

          <AosLib value="4.9* Google Rating" />
          <AosLib value="Executed over 330+ projects successfully" />
        </div>
      </div>
      <Strategy />
      <Contact />
      <Footer />
    </>
  );
}

export default Home;
