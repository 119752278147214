import "./ReUsableUser.css";
import { MdOutlineDelete } from "react-icons/md";
import { arrayRemove, doc, updateDoc } from "firebase/firestore";
import { db } from "../components/firebase";
function ReUsableUser({ resourceName, teamId }) {
  const handleDeleteUser = async (data) => {
    const deleteUserFromTeam = doc(db, "teams", teamId);

    await updateDoc(deleteUserFromTeam, {
      users: arrayRemove(data),
    });

    console.log("You clicked on", data);
  };

  return (
    <div className="reusableUser">
      {resourceName.length > 0 &&
        resourceName.map((data) => (
          <div key={data.id} className="reusableUser__Item">
            <img
              src={data.photoURL}
              alt={data.email}
              className="assign__Image"
            />

            <h3 className="common__Title">{data.email}</h3>
            <MdOutlineDelete
              onClick={() => handleDeleteUser(data)}
              className="allBlogs__Operations danger__Color"
            />
          </div>
        ))}
    </div>
  );
}

export default ReUsableUser;
