import React, { useState } from "react";
import { NavLink, useNavigate } from "react-router-dom";
import "./Header.css";
import Logo from "../components/images/finalLogo.svg";
import NavbarLinks from "../libs/NavbarLinks";
import menu from "./images/menu.svg";
import { useSelector } from "react-redux";
import { selectUser } from "../app/slices/userSlice";
import { auth } from "./firebase";
import { useAppContext } from "../libs/contextLib";
import { signOut } from "firebase/auth";

function Header() {
  const [sidebar, setSidebar] = useState(false);
  const showSidebar = () => setSidebar(!sidebar);
  const user = useSelector(selectUser);
  const { main, setMain } = useAppContext();
  const navigate = useNavigate();

  const handleSignOut = async () => {
    signOut(auth);
    navigate("/");
    setMain(null);
    await JSON.parse(
      localStorage?.setItem("admin", JSON?.stringify(null) || "")
    );
  };
  return (
    <div className="header">
      <NavLink to="/">
        <img className="header__Logo" src={Logo} alt="Logo" />
      </NavLink>

      <div className="header__Links">
        <NavbarLinks to="/about" value="About" />
        <NavbarLinks to="/blogs" value="Blogs" />
        <NavbarLinks to="/feedback" value="Feedback" />
        {!main && !user ? (
          <NavbarLinks to="/login" value="Dashboard" />
        ) : main && user ? (
          <NavbarLinks to={"/admin"} value="Admin" />
        ) : (
          user && !main && <NavbarLinks to="/client" value="Client" />
        )}
        {user && (
          <button className="header__SignOut" onClick={handleSignOut}>
            LogOut
          </button>
        )}

        {/* <img src={user.photo} className="message__photo" /> */}
      </div>

      <div className={sidebar ? "nav-menu active" : "nav-menu"}>
        <NavbarLinks to="/about" value="About" />
        <NavbarLinks to="/blogs" value="Blogs" />
        <NavbarLinks to="/feedback" value="Feedback" />
        {main && <NavbarLinks to="/admin" value="Admin" />}
        {user && (
          <button className="header__SignOut" onClick={() => handleSignOut()}>
            Logout
          </button>
        )}
      </div>
      {!sidebar ? (
        <img
          onClick={showSidebar}
          className="header__Menu close"
          src={menu}
          alt="menu"
        />
      ) : (
        <i
          onClick={showSidebar}
          className="fa fa-times close"
          aria-hidden="true"
        ></i>
      )}
    </div>
  );
}

export default Header;
