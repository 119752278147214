import { useState } from "react";
import Button from "../../libs/Button";
import "./AddAdmin.css";
import { getFunctions, httpsCallable } from "firebase/functions";

function AddAdmin() {
  const [email, setEmail] = useState("");
  const [loading, setLoading] = useState(false);
  const handleSubmit = (e) => {
    e.preventDefault();
    console.log("Loading");
    const testFunctions = httpsCallable(
      getFunctions(),
      process.env.REACT_APP_ADD_ADMIN_ROLE
    );

    testFunctions(
      {
        email: email,
      },
      setLoading(true)
    ).then((result) => {
      console.log(result);
      setEmail("");
    });
  };

  const handleManagement = (e) => {
    e.preventDefault();
    console.log("Loading");
    const testFunctions = httpsCallable(
      getFunctions(),
      process.env.REACT_APP_ADMIN_USER_FUNCTION
    );

    testFunctions(
      {
        email: email,
      },
      setLoading(true)
    ).then((result) => {
      console.log(result);
      setEmail("");
    });
  };
  return (
    <div>
      {/* {main && user && ( */}
      <>
        {/* <form onSubmit={handleSubmit} className="admin__FormPanel">
          <input
            type="email"
            className="contact__Name"
            placeholder="Enter email-Id"
            onChange={(e) => setEmail(e.target.value)}
            value={email}
          />

          <Button
            onClick={handleSubmit}
            value={loading ? "Loading..." : "Make Admin"}
          />
        </form> */}
        <form onSubmit={handleSubmit} className="admin__FormPanel">
          <input
            type="email"
            className="contact__Name"
            placeholder="Enter marketing teams email address"
            onChange={(e) => setEmail(e.target.value)}
            value={email}
          />

          <Button
            onClick={handleSubmit}
            value={loading ? "Loading..." : "Add Addmin"}
          />
        </form>
      </>
      {/* )} */}
    </div>
  );
}

export default AddAdmin;
