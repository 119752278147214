import "./Strategy.css";
import Analysis from "./images/Analysis.svg";
import Strategy1 from "./images/Strategy.svg";
import Creation from "./images/Creation.svg";

function Strategy() {
  return (
    <div
      className="strategy"
      data-aos="fade-down"
      data-aos-easing="linear"
      data-aos-duration="1500"
    >
      <h2 className="h2__Title strategy__Title">Our Process</h2>

      <div className="strategy__Process">
        <div className="strategy__Card">
          <img src={Analysis} alt="Analysis" className="strategy__SVG" />

          <h4 className="strategy__Progress">Analysis</h4>

          <h4 className="h5__Color h5__Font"> Step 1</h4>
        </div>
        <div className="strategy__Card">
          <img src={Strategy1} alt="Strategy" className="strategy__SVG" />
          <h4 className="strategy__Progress">Strategy</h4>
          <h4 className="h5__Color h5__Font"> Step 2</h4>
        </div>
        <div className="strategy__Card">
          <img src={Creation} alt="Creation" className="strategy__SVG" />

          <h4 className="strategy__Progress">Achievement</h4>
          <h4 className="h5__Color h5__Font"> Step 3</h4>
        </div>
      </div>
      <div className="strategy__Business">
        <h2 className="h2__Title strategy__Title">
          How we're helping businesses?
        </h2>

        <h5 className="h5__Font">
          At Bizkey, we assist and facilitate organizations in obtaining
          certification to ISO 9001:2015 || ISO 14001:2015 || ISO 45001:2018 ||
          ISO 22000:2018 || FSSC 22000:2018 || NABL || NABH ||GMP || BRC ||
          HACCP Kosher Halal in the most Effective Economical Time-bound and
          Easy to Implement Manner Along with our Consulting services in FSSAI
          Licence Trademark(TM and Registration) APEDA Certificate Import-export
          licence(IEC) and GPCB. "Whatever business you are in, whatever your
          business-size at Bizkey, you will be treating equally. We propose
          strong communication and mutual understanding to build long lasting
          relationship and most importantly enjoy our clients success.
        </h5>
      </div>
    </div>
  );
}

export default Strategy;
