import "./AllBlogs.css";
import { MdOutlineDelete } from "react-icons/md";
import { FiEdit2 } from "react-icons/fi";
import { db, storage } from "../firebase";
import { deleteDoc, doc } from "firebase/firestore";
import { useEffect, useState } from "react";
import { ref, deleteObject } from "firebase/storage";

import { GrNext } from "react-icons/gr";

import { MdOutlineArrowBackIosNew as Back } from "react-icons/md";
import { useResource } from "../../hooks/useResource";
import ResourceService from "../../libs/resource";
function AllBlogs() {
  const blogsData = useResource(process.env.REACT_APP_BLOGS);

  const [currentPage, setCurrentPage] = useState(1);
  const [pages] = useState(
    blogsData.length > 0 ? Math.round(blogsData?.length / 3) - 1 : 0
  );

  useEffect(() => {
    window.scrollTo({ behavior: "smooth", top: "0px" });

    return () => null;
  }, [currentPage, pages]);

  const [open, setOpen] = useState(false);

  // For DELETE A BLOG

  const deleteBlog = async (id, url) => {
    const storageRef = ref(storage, `${url}`);

    await ResourceService.deleteResource(process.env.REACT_APP_BLOGS, id).then(
      async () => {
        await deleteObject(storageRef).then(() => {});
      }
    );
  };

  // FOR EDIT A BLOG
  const handleEdit = (id, url) => {
    // id.preventDefault();
    setOpen(true);
  };

  // For Pagination

  const pageLimit = 5;

  const lastBlog = blogsData.slice(blogsData.length - 1);

  const goToNextPage = () => setCurrentPage(currentPage + 1);

  const goToPreviousPage = () => setCurrentPage(currentPage - 1);

  const changePage = (e) => {
    const pageNumber = Number(e.target.textContent);
    setCurrentPage(pageNumber);
  };

  const getPageinatedData = () => {
    const startIdx = currentPage * 5 - 5;

    const endIdx = startIdx + 5;

    return blogsData.slice(startIdx, endIdx);
  };

  const paginationGroup = () => {
    let start = Math.floor((currentPage - 1) / pageLimit) * pages;

    return new Array(pages).fill().map((_, idx) => start + idx + 1);
  };

  return (
    <>
      <div className="allBlogs">
        <h2 className="h2__Title">
          Showing 5 blogs out of {blogsData?.length} blogs
        </h2>

        <div className="allBlogs__Main">
          {getPageinatedData().map((blog, index) => (
            <div key={blog.id} className="allBlogs__Row">
              <h6>{index}</h6>
              <h5 className="h5__Font allBlogs__Title">
                {blog.name.slice(0, 5)}...
              </h5>
              <img
                src={blog.urls}
                className="profile__Round margin__Bottom allBlogs__Image"
                alt={blog.name}
              />

              <FiEdit2
                onClick={() => handleEdit(blog.id, blog.urls)}
                className="allBlogs__Operations"
              />
              <MdOutlineDelete
                onClick={() => deleteBlog(blog.id, blog.urls)}
                className="allBlogs__Operations"
              />
            </div>
          ))}
        </div>

        <Back
          onClick={goToPreviousPage}
          className={`prev ${currentPage === pages ? "disabled" : ""}`}
        />

        {paginationGroup().map((item, index) => (
          <button key={index} onClick={changePage} className="prev">
            {item}
          </button>
        ))}

        <GrNext
          onClick={goToNextPage}
          className={`next ${currentPage === pages ? "disabled" : ""}`}
        />
      </div>
    </>
  );
}

export default AllBlogs;
