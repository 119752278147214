const Data = () => (
  <svg
    width="500"
    height="400"
    viewBox="0 0 760 500"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g id="trashSvg 1" clipPath="url(#clip0_27_3)">
      <path
        id="Path 1"
        d="M159.943 498.096H43.8334C43.8334 498.096 41.4798 457.521 55.4047 457.06C69.3295 456.599 67.7605 475.042 85.2166 449.682C102.673 424.322 123.854 425.706 126.6 440.692C129.346 455.677 121.305 467.665 136.014 463.976C150.723 460.287 171.906 469.97 159.943 498.096Z"
        fill="#E6E6E6"
      />
      <path
        id="Path 2"
        d="M99.9285 498.106L99.5362 498.088C100.45 471.374 105.21 454.212 109.042 444.514C113.203 433.988 117.212 429.726 117.252 429.685L117.513 430.029C117.474 430.071 113.511 434.29 109.384 444.745C105.573 454.398 100.839 471.492 99.9285 498.106Z"
        fill="white"
      />
      <path
        id="Path 3"
        d="M141.951 498.161L141.574 498.031C144.494 486.709 149.758 476.416 156.894 468.072L157.157 468.414C150.074 476.699 144.85 486.92 141.951 498.161Z"
        fill="white"
      />
      <path
        id="Path 4"
        d="M62.1219 498.128L61.7329 498.065C62.9989 488.05 61.6595 477.818 57.8904 468.713C56.3453 464.894 54.2999 461.384 51.8289 458.311L52.1006 457.978C54.6051 461.088 56.6786 464.642 58.2455 468.508C62.0485 477.696 63.3997 488.021 62.1219 498.128V498.128Z"
        fill="white"
      />
      <path
        id="Path 5"
        d="M175.7 498.713H31.3107L31.2777 498.138C31.1951 496.708 29.3527 462.971 38.6522 450.934C39.5748 449.662 40.7137 448.631 41.9957 447.908C43.2776 447.185 44.6741 446.786 46.0953 446.735C52.1567 446.532 55.9662 449.202 59.0391 451.343C64.8305 455.381 68.6557 458.05 82.5665 437.841C97.6351 415.948 113.895 411.237 123.241 413.856C129.28 415.549 133.442 420.332 134.661 426.98C135.817 433.275 135.447 439.19 135.151 443.943C134.835 449.029 134.585 453.045 136.363 454.866C137.849 456.385 140.806 456.523 145.679 455.301C155.588 452.816 169.419 454.942 176.36 465.484C180.094 471.156 183.037 481.465 175.845 498.372L175.7 498.713ZM32.2959 497.479H175.05C180.409 484.7 180.577 473.903 175.53 466.24C169.162 456.568 155.803 454.023 145.897 456.507C140.603 457.835 137.449 457.616 135.673 455.797C133.5 453.572 133.767 449.283 134.104 443.857C134.395 439.183 134.755 433.366 133.634 427.243C132.504 421.078 128.627 416.637 122.997 415.06C113.948 412.522 98.1397 417.173 83.3816 438.619C68.9018 459.656 64.539 456.616 58.5031 452.403C55.3923 450.235 51.8611 447.776 46.1242 447.967C44.8446 448.015 43.5879 448.378 42.4347 449.031C41.2816 449.685 40.2575 450.615 39.4285 451.761C31.0729 462.581 32.0919 492.918 32.2967 497.479H32.2959Z"
        fill="#2F2E41"
      />
      <path
        id="Path 8"
        d="M647.488 499.398H505.269L505.242 498.461L498.451 270.17H654.305L647.488 499.398ZM506.865 497.457H645.892L652.595 272.111H500.161L506.865 497.457Z"
        fill="#3F3D56"
      />
      <g id="Group 1">
        <path
          id="Rectangle 17"
          d="M539.195 312.468H528.377V469.818H539.195V312.468Z"
          fill="#3F3D56"
        />
        <path
          id="Rectangle 18"
          d="M581.787 312.468H570.969V469.818H581.787V312.468Z"
          fill="#3F3D56"
        />
        <path
          id="Rectangle 19"
          d="M624.379 312.468H613.562V469.818H624.379V312.468Z"
          fill="#3F3D56"
        />
      </g>
      <path
        id="Vector"
        d="M744.677 337.208L743.979 336.689L567.326 205.465L582.649 176.964L583.348 177.482L760 308.705L744.677 337.208ZM569.605 204.861L744.163 334.529L757.721 309.31L583.164 179.641L569.605 204.861Z"
        fill="#3F3D56"
      />
      <path
        id="Path 10"
        d="M702.026 194.889C696.891 191.069 690.932 189.061 684.855 189.103C678.779 189.145 672.84 191.235 667.743 195.126C662.647 199.016 658.607 204.544 656.103 211.052C653.599 217.56 652.736 224.777 653.617 231.844L662.04 238.101C659.923 232.324 659.436 225.901 660.649 219.774C661.862 213.647 664.711 208.138 668.781 204.052C672.85 199.967 677.926 197.52 683.264 197.07C688.602 196.621 693.922 198.192 698.445 201.553C702.969 204.915 706.458 209.89 708.402 215.751C710.346 221.612 710.642 228.051 709.248 234.124C707.854 240.198 704.842 245.587 700.654 249.503C696.465 253.419 691.32 255.655 685.972 255.886L694.396 262.143C700.205 260.047 705.381 256.035 709.308 250.585C713.236 245.135 715.752 238.474 716.556 231.394C717.36 224.314 716.419 217.111 713.845 210.641C711.271 204.17 707.172 198.703 702.034 194.889H702.026Z"
        fill="#3F3D56"
      />
      <path
        id="Rectangle 21"
        d="M750.665 498.059H0V500H750.665V498.059Z"
        fill="#3F3D56"
      />
      <g id="Group6" filter="url(#filter0_d_27_3)">
        <path
          id="Path 111"
          d="M327.879 484.811H315.851L310.127 430.283H327.882L327.879 484.811Z"
          fill="#FEB8B8"
        />
        <path
          id="Path 112"
          d="M292.657 497.937H330.455V480.771H307.058C303.239 480.771 299.576 482.555 296.875 485.729C294.174 488.904 292.657 493.21 292.657 497.699V497.699V497.937Z"
          fill="#2F2E41"
        />
        <path
          id="Path 113"
          d="M323.651 400.316L331.948 410.552L369.482 377.813L357.239 362.706L323.651 400.316Z"
          fill="#FEB8B8"
        />
        <path
          id="Path 114"
          d="M340.494 415.242L324.35 395.33L313.777 407.175L339.858 439.342L340.004 439.178C341.374 437.644 342.472 435.808 343.238 433.775C344.004 431.742 344.421 429.552 344.467 427.329C344.512 425.106 344.185 422.895 343.503 420.821C342.821 418.747 341.799 416.851 340.494 415.242V415.242Z"
          fill="#2F2E41"
        />
        <path
          id="Path 115"
          d="M239.939 250.925C239.177 249.486 238.694 247.868 238.522 246.185C238.35 244.502 238.495 242.796 238.945 241.185C239.395 239.575 240.139 238.1 241.126 236.865C242.114 235.63 243.319 234.664 244.658 234.035L291.877 92.5763L310.283 105.53L257.829 241.236C258.579 243.951 258.455 246.895 257.481 249.509C256.507 252.124 254.751 254.228 252.546 255.423C250.34 256.617 247.839 256.819 245.515 255.99C243.191 255.161 241.207 253.359 239.939 250.925V250.925Z"
          fill="#FEB8B8"
        />
        <path
          id="Path 116"
          d="M419.167 261.064C417.728 260.944 416.329 260.453 415.069 259.628C413.809 258.803 412.718 257.663 411.874 256.288C411.029 254.914 410.451 253.339 410.181 251.673C409.91 250.008 409.954 248.293 410.308 246.649L340.647 118.643L360.377 108.742L423.003 238.354C425.316 239.232 427.274 241.079 428.505 243.542C429.736 246.006 430.154 248.915 429.681 251.717C429.208 254.519 427.875 257.02 425.937 258.744C423.999 260.468 421.59 261.295 419.167 261.068V261.064Z"
          fill="#FEB8B8"
        />
        <path
          id="Path 117"
          d="M292.342 236.761L304.672 452.174L334.139 449.118L340.206 290.192L356.672 358.446L392.205 361.502L378.141 226.573L292.342 236.761Z"
          fill="#2F2E41"
        />
        <path
          id="Path 118"
          d="M361.872 348.26L356.67 358.447L320.274 387.99L346.274 404.29C346.274 404.29 396.54 371.691 392.207 361.503L361.872 348.26Z"
          fill="#2F2E41"
        />
        <path
          id="Path 119"
          d="M266.539 149.606L276.796 115.632C279.078 108.072 282.613 101.12 287.196 95.1765C291.779 89.2334 297.32 84.4178 303.497 81.0082V81.0082C317.38 74.9961 332.48 74.0245 346.839 78.2194L350.571 79.3085C360.525 82.1444 369.851 87.4389 377.929 94.8399C384.25 100.697 389.936 108.113 390.442 116.091C390.445 116.108 390.449 116.124 390.455 116.14C392.205 125.16 393.072 172.021 393.072 172.021H377.629L380.072 235.182L379.874 234.725C379.874 234.725 290.805 254.538 290.805 244.351V179.15L288.98 155.542L266.539 149.606Z"
          fill="#CCCCCC"
        />
        <path
          id="Ellipse 12"
          d="M349.676 69.3879C363.308 69.3879 374.359 56.398 374.359 40.3743C374.359 24.3505 363.308 11.3607 349.676 11.3607C336.044 11.3607 324.993 24.3505 324.993 40.3743C324.993 56.398 336.044 69.3879 349.676 69.3879Z"
          fill="#FEB8B8"
        />
        <path
          id="Path 120"
          d="M353.373 27.3629L372.539 28.2657C374.95 28.257 377.583 28.157 379.419 26.3243C382.186 23.5587 381.723 18.3402 380.241 14.4418C376.112 3.58733 366.902 -0.301333 356.788 0.0180304C346.674 0.337394 336.077 4.3668 330.63 14.3845C325.184 24.4023 323.712 37.0526 325.764 48.6933C328.135 42.7742 331.786 37.6897 336.352 33.9475C340.918 30.2053 346.239 27.9362 351.784 27.3668L353.373 27.3629Z"
          fill="#2F2E41"
        />
      </g>
      <g id="Group 4">
        <path
          id="Path 81"
          d="M467.182 311.156L397.813 254.008C397.168 253.474 396.73 252.663 396.593 251.75C396.456 250.838 396.633 249.899 397.084 249.14L451.531 157.822C451.985 157.065 452.675 156.549 453.451 156.389C454.228 156.228 455.026 156.436 455.672 156.966L525.042 214.115C525.687 214.648 526.125 215.459 526.262 216.372C526.398 217.284 526.222 218.223 525.771 218.983L471.325 310.298C470.872 311.056 470.181 311.572 469.404 311.733C468.628 311.894 467.829 311.687 467.182 311.156V311.156Z"
          fill="#6C63FF"
        />
        <path
          id="Path 82"
          d="M482.783 249.146L442.078 215.612C441.607 215.224 441.206 214.731 440.897 214.161C440.588 213.591 440.378 212.955 440.279 212.29C440.179 211.625 440.192 210.943 440.317 210.283C440.441 209.623 440.675 208.999 441.005 208.446C441.335 207.892 441.755 207.421 442.24 207.058C442.725 206.695 443.266 206.448 443.832 206.331C444.398 206.214 444.978 206.229 445.539 206.376C446.1 206.522 446.631 206.797 447.102 207.185L487.807 240.719C488.758 241.502 489.405 242.697 489.606 244.041C489.807 245.385 489.546 246.768 488.879 247.886C488.213 249.003 487.197 249.764 486.053 250C484.91 250.236 483.733 249.929 482.783 249.146V249.146Z"
          fill="white"
        />
        <path
          id="Path 83"
          d="M473.441 264.814L432.736 231.28C432.264 230.893 431.863 230.4 431.554 229.83C431.244 229.26 431.034 228.624 430.934 227.958C430.834 227.292 430.847 226.61 430.972 225.95C431.096 225.289 431.33 224.665 431.66 224.111C431.99 223.558 432.41 223.086 432.895 222.723C433.381 222.36 433.922 222.113 434.489 221.996C435.055 221.879 435.636 221.895 436.197 222.042C436.758 222.189 437.29 222.465 437.76 222.853L478.465 256.387C479.415 257.171 480.061 258.366 480.261 259.709C480.462 261.052 480.2 262.434 479.534 263.551C478.869 264.668 477.853 265.428 476.71 265.665C475.567 265.902 474.391 265.596 473.441 264.814V264.814Z"
          fill="white"
        />
        <path
          id="Path 84"
          d="M463.998 280.651L423.293 247.117C422.823 246.729 422.421 246.236 422.113 245.666C421.804 245.096 421.594 244.46 421.494 243.795C421.395 243.129 421.408 242.447 421.532 241.788C421.657 241.128 421.891 240.504 422.221 239.95C422.551 239.397 422.97 238.925 423.455 238.563C423.94 238.2 424.481 237.953 425.047 237.836C425.613 237.719 426.194 237.734 426.755 237.88C427.316 238.027 427.847 238.302 428.318 238.69L469.023 272.224C469.974 273.007 470.621 274.202 470.822 275.546C471.023 276.89 470.761 278.273 470.095 279.39C469.429 280.508 468.412 281.268 467.269 281.505C466.125 281.741 464.949 281.434 463.998 280.651V280.651Z"
          fill="white"
        />
        <path
          id="Path 85"
          d="M482.668 218.151L466.902 205.163C466.431 204.775 466.029 204.282 465.72 203.712C465.411 203.142 465.2 202.506 465.1 201.84C465.001 201.174 465.013 200.492 465.138 199.832C465.262 199.172 465.496 198.547 465.827 197.993C466.157 197.44 466.576 196.968 467.062 196.605C467.547 196.242 468.088 195.995 468.655 195.879C469.221 195.762 469.802 195.777 470.363 195.924C470.925 196.071 471.456 196.347 471.927 196.736L487.693 209.724C488.644 210.507 489.291 211.702 489.492 213.046C489.693 214.39 489.431 215.773 488.765 216.891C488.099 218.008 487.082 218.769 485.939 219.005C484.795 219.242 483.619 218.934 482.668 218.151V218.151Z"
          fill="white"
        />
      </g>
    </g>
    <defs>
      <filter
        id="filter0_d_27_3"
        x="234.452"
        y="0"
        width="199.419"
        height="505.937"
        filterUnits="userSpaceOnUse"
        colorInterpolationFilters="sRGB"
      >
        <feFlood floodOpacity="0" result="BackgroundImageFix" />
        <feColorMatrix
          in="SourceAlpha"
          type="matrix"
          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
          result="hardAlpha"
        />
        <feOffset dy="4" />
        <feGaussianBlur stdDeviation="2" />
        <feComposite in2="hardAlpha" operator="out" />
        <feColorMatrix
          type="matrix"
          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.25 0"
        />
        <feBlend
          mode="normal"
          in2="BackgroundImageFix"
          result="effect1_dropShadow_27_3"
        />
        <feBlend
          mode="normal"
          in="SourceGraphic"
          in2="effect1_dropShadow_27_3"
          result="shape"
        />
      </filter>
      <clipPath id="clip0_27_3">
        <rect width="760" height="500" fill="white" />
      </clipPath>
    </defs>
  </svg>
);

export default Data;
