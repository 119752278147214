import "./App.css";
import "./css/common.css";
import { BrowserRouter, Routes, Route, Navigate } from "react-router-dom";

//
import Home from "./components/Home";
import About from "./components/About";
import Feedback from "./components/Feedback";
import Blogs from "./components/Blogs";
import Login from "./components/Login";
import Admin from "./components/Admin/Admin";
import Client from "./components/client/Client";
import Timeline from "./components/Admin/Timeline";
import Teams from "./components/Admin/Teams";
import Chats from "./components/Admin/Chats";
import RecycleProject from "./components/Admin/RecycleBin/RecycleProject";

import { AppContext } from "./libs/contextLib";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { auth } from "./components/firebase";
import { login, logout, selectUser } from "./app/slices/userSlice";
import Notification from "./components/Admin/Notification";
import NotFound from "./libs/NotFound";

function App() {
  const user = useSelector(selectUser);
  const dispatch = useDispatch();
  const [main, setMain] = useState(
    null || JSON.parse(localStorage.getItem("admin"))
  );

  // admin.auth().getUser(uid).then((userRecord) => {
  // The claims can be accessed on the user record.
  // console.log(userRecord.customClaims.admin);
  // });

  useEffect(() => {
    let isMounted = true;
    if (isMounted) {
      auth.onAuthStateChanged((authUser) => {
        if (authUser) {
          authUser.getIdTokenResult().then((idTokenResult) => {
            authUser.admin = idTokenResult.claims.admin;
            // authUser.marketing = idTokenResult.claims.accessLevel;
            setMain(authUser.admin !== undefined ? authUser.admin : null);
            localStorage.setItem("admin", JSON.stringify(main));
          });
          dispatch(
            login({
              uid: authUser.uid,
              photo: authUser.photoURL,
              email: authUser.email,
              displayName: authUser.email.substring(
                0,
                authUser.email.lastIndexOf("@")
              ),
            })
          );
        } else {
          dispatch(logout());
        }
      });
    }
    return () => {
      isMounted = false;
    };
  }, [dispatch, main]);

  return (
    <AppContext.Provider value={{ main, setMain }}>
      <div className="App">
        <BrowserRouter>
          <Routes>
            <Route path="/" element={<Home />} />
            <Route path="about" element={<About />} />
            <Route path="blogs" element={<Blogs />} />
            <Route path="feedback" element={<Feedback />} />
            <Route path="login" element={<Login />} />
            {user && !main && <Route path="client" element={<Client />} />}
            {/* {user && !main && <Route path="/chats" element={<Chats />} />} */}
            <Route path="/chats" element={<Chats />} />
            <Route
              path={"/admin"}
              element={main === true ? <Admin /> : <Navigate to="/login" />}
            />

            <Route path="/admin/notifications" element={<Notification />} />
            <Route path="/admin/timeline" element={<Timeline />} />
            <Route path="/admin/teams" element={<Teams />} />
            <Route path="/admin/recycleBin" element={<RecycleProject />} />

            <Route path="*" element={<NotFound />} />
          </Routes>
        </BrowserRouter>
      </div>
    </AppContext.Provider>
  );
}

export default App;
