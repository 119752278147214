import { useState } from "react";
import "./AddProject.css";
import DatePicker from "react-datepicker";
import Button from "../../libs/Button";
import ResourceService from "../../libs/resource";
import { useSelector } from "react-redux";
import { selectUser } from "../../app/slices/userSlice";
import { useFormInput } from "../../hooks/useFormInput";
function AddProject({ handleAddProject }) {
  const user = useSelector(selectUser);
  const [name, resetName] = useFormInput("");
  const [priority, resetPriority] = useFormInput(0);
  const [tags, resetTags] = useFormInput("");
  const [dateRange, setDateRange] = useState([null, null]);

  const [startDate, endDate] = dateRange;

  const handleProject = async (e) => {
    e.preventDefault();
    const newProject = {
      startDate: new Date(startDate).getTime(),
      endDate: new Date(endDate).getTime(),
      name: name.value,
      priority: priority.value,
      tags: tags.value,
      status: "Not Started",
      createdBy: user.displayName,
      uid: user.uid,
      photo: user.photo,
    };

    await ResourceService.createResource(
      newProject,
      process.env.REACT_APP_PROJECTS
    );
    resetTags("");

    setDateRange([null, null]);
    resetName("");
    resetPriority(0);
    handleAddProject();
  };

  return (
    <div className="addProject">
      <h2 className="medium__Fonts">Create New Project</h2>
      <form className="addProject__Form">
        <input
          type="text"
          placeholder="Enter Project /Task Name"
          className="addProject__FormInput small__Fonts"
          {...name}
        />
        <input
          type="number"
          placeholder="Enter Project /Task Name"
          className="addProject__FormInput small__Fonts"
          {...priority}
        />
        <input
          type="text"
          placeholder="Enter Tags"
          className="addProject__FormInput small__Fonts"
          {...tags}
        />
        <DatePicker
          className="addProject__FormInput small__Fonts"
          selectsRange={true}
          dateFormat="MMMM d, yyyy"
          startDate={startDate}
          endDate={endDate}
          onChange={(update) => setDateRange(update)}
          placeholderText="Pick a date"
        />
        <Button value="Create" onClick={handleProject} />
      </form>
    </div>
  );
}

export default AddProject;
