import Footer from "../Footer";
import Header from "../Header";
import AdminSidebar from "./AdminSidebar";
import ListTeam from "./ListTeam";
import UsersDropDown from "./UsersDropDown";

function Teams() {
  // Fetch User from Redux

  return (
    <>
      <div className="admin__Main">
        <AdminSidebar />
        <div className="teams">
          <Header />

          <div className="teams__Main">
            <div className="teams__Group">
              <ListTeam />
            </div>

            <div className="teams__Right">
              <UsersDropDown />
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </>
  );
}

export default Teams;
