import Button from "./Button";
import "./Modals.css";

function Modals({ title, onClick, onCancel, actionTitle, styleClass }) {
  return (
    <div
      className={`${
        styleClass != null ? styleClass : "modals scale-up-ver-center"
      }`}
    >
      <h2 className="medium__Fonts">{title}</h2>
      <div className="modals__Buttons">
        <Button
          onClick={() => {
            onClick();
          }}
          value={actionTitle}
        />
        <Button
          onClick={onCancel}
          value="Cancel"
          styleClass="feature__Danger__Btn"
        />
      </div>
    </div>
  );
}

export default Modals;
