import "./Invoice.css";
import Button from "../../libs/Button";
import axios from "axios";
import { MdOutlineClose } from "react-icons/md";
import { getFunctions, httpsCallable } from "firebase/functions";
import ResourceService from "../../libs/resource";
import { useFormInput } from "../../hooks/useFormInput";
function Invoice() {
  const [email, resetEmail] = useFormInput("");
  const [price, resetPrice] = useFormInput(0);
  const [name, resetName] = useFormInput("");
  const [success, resetSuccess] = useFormInput(false);
  const [company, resetCompany] = useFormInput("");
  const [service, resetService] = useFormInput("");
  const [reminder, resetReminder] = useFormInput(false);

  // process.env.REACT_APP_CONTACT_URL
  const newReminder = {
    company: company.value,
    email: email.value,
    service: service.value,
    price: price.value,
    name: name.value,
    reminder: reminder.value,
  };

  const createPdfLink = async (e) => {
    e.preventDefault();
    const response = await axios.post(
      // "http://localhost:5001/bizkey-solution/us-central1/getPdfUrls/getPdfUrls",
      process.env.REACT_APP_GET_PDF_URLS,
      {
        company,
        email,
        service,
        price,
        name,
        reminder,
      }
    );

    console.log(response);
  };
  const handleSubmit = async (e) => {
    e.preventDefault();

    const response = await axios
      .post(
        // process.env.REACT_APP_INVOICE_URL,
        "http://localhost:5001/bizkey-solution/us-central1/sendInvoice/invoice",
        newReminder
      )
      .then(async () => {
        await ResourceService.createResource(
          newReminder,
          process.env.REACT_APP_REMINDER
        );
      });

    console.log("Contact sent successfully", response);
    resetCompany("");
    resetEmail("");
    resetPrice(0);
    resetService("");
    resetName("");
    resetSuccess(true);
  };

  const handleClose = (e) => {
    e.preventDefault();

    resetSuccess(false);
  };

  const handleCreatePdf = async (e) => {
    e.preventDefault();
    console.log("Loading");
    const testFunctions = httpsCallable(
      getFunctions({
        newReminder,
      }),
      "getPdfUrl"
    );

    await testFunctions().then((result) => {
      console.log(result);
    });
  };

  const getAdmins = async (e) => {
    e.preventDefault();
    console.log("Loading");
    const testFunctions = httpsCallable(
      getFunctions(),
      process.env.REACT_APP_ADMINUSER_FUNCTION
    );

    await testFunctions().then((result) => {
      console.log(result);
    });
  };

  return (
    <>
      {success.value ? (
        <div className="contact__Success">
          <h3 className="h5__Font h5__Color">
            Message: We received your message
          </h3>
          <br />
          <h5 className="h5__Font h5__Success">
            We have successfully sent a invoice. You and your client will
            receive a copy of invoice for a reference.
          </h5>
          <MdOutlineClose
            onClick={handleClose}
            className="h2__Title about__Close"
          />
        </div>
      ) : (
        ""
      )}

      <div className="invoice" id="invoice">
        <h2 className="h2__Title">Send Invoice</h2>
        <div className="invoice__Main">
          {/* <form type="submit" onSubmit={handleSubmit} className="invoice__Form"> */}
          <form
            type="submit"
            onSubmit={createPdfLink}
            className="invoice__Form"
          >
            <input
              className="contact__Name"
              type="text"
              {...name}
              placeholder="Enter Client Name"
              required
            />
            <input
              className="contact__Name"
              type="text"
              {...company}
              placeholder="Enter Company Name"
              required
            />
            <input
              className="contact__Name"
              type="email"
              {...email}
              placeholder="Client's Email"
              required
            />

            <input
              className="contact__Name"
              type="text"
              {...service}
              placeholder="Enter service name"
              required
            />

            <input
              className="contact__Name"
              type="number"
              {...price}
              placeholder="Enter invoice amount"
              required
            />

            <Button value="Send Invoice" onClick={handleSubmit} />
            {/* <Button value="Create Pdf" onClick={handleCreatePdf} /> */}
            <Button value="Get PDF LINK" onClick={createPdfLink} />
          </form>
        </div>
      </div>
    </>
  );
}

export default Invoice;
