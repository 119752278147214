import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { collection, getDocs } from "firebase/firestore";
import { db } from "../../components/firebase";

const initialState = {
  entities: [],
  reminders: [],
  loading: false,
};

export const getReminders = createAsyncThunk(
  "reminders/getReminders",
  async () => {
    const reminderRef = collection(db, "reminder");
    const newReminders = await getDocs(reminderRef);
    const reminderData = newReminders.docs.map((doc) => ({
      ...doc.data(),
      id: doc.id,
    }));

    return reminderData;
  }
);
export const getPosts = createAsyncThunk("posts/getPosts", async (thunkAPI) => {
  const projectRef = collection(db, "projects");

  const newItems = await getDocs(projectRef);
  const data2 = newItems.docs.map((doc) => ({
    ...doc.data(),
    id: doc.id,
  }));
  return data2;
});

export const postSlice = createSlice({
  name: "posts",
  initialState,
  reducers: {},
  extraReducers: {
    [getPosts.pending]: (state) => {
      state.loading = true;
    },
    [getPosts.fulfilled]: (state, { payload }) => {
      state.loading = false;
      state.entities = payload;
    },
    [getPosts.rejected]: (state) => {
      state.loading = false;
    },
    [getReminders.pending]: (state) => {
      state.loading = true;
    },
    [getReminders.fulfilled]: (state, { payload }) => {
      state.loading = false;
      state.reminders = payload;
    },
    [getReminders.rejected]: (state) => {
      state.loading = false;
    },
  },
});

export default postSlice.reducer;
