import "./Projects.css";
import { useState } from "react";
import AddProject from "./AddProject";
import { FaPlusCircle } from "react-icons/fa";
import Button from "../../libs/Button";
import Chats from "./Chats";
import ResourceService from "../../libs/resource";
import { toast, ToastContainer } from "react-toastify";
import { useResource } from "../../hooks/useResource";
import { useSelector } from "react-redux";
import { selectUser } from "../../app/slices/userSlice";
import {
  currentMonthResultData,
  nextMonthResultData,
} from "./utils/projectsUtils";
import ProjectsDataTable from "./Projects/ProjectsDataTable";
import Activities from "./Profile/Activities";

function Projects() {
  const [open, setOpen] = useState(false);
  const [addProject, setAddProject] = useState(true);

  const projectData2 = useResource(process.env.REACT_APP_PROJECTS);
  const user = useSelector(selectUser);
  const projectData = projectData2.filter((item) => item.uid === user.uid);

  const handleAddProject = () => {
    setAddProject(!addProject);
  };

  const handleChange = async (id, status) => {
    try {
      const updateData = {
        status: status,
      };

      await ResourceService.updateResource(
        id,
        updateData,
        process.env.REACT_APP_PROJECTS
      );
      setOpen(false);
      const notify = () => toast.success("Status Changed");
      notify();
    } catch {
      const notify = () => toast.warning("Unauthorized !!");
      notify();
    }
  };

  const nextMonthResult = nextMonthResultData(projectData);

  const currentMonthResult = currentMonthResultData(projectData);

  return (
    <>
      <ToastContainer
        astContainer
        position="top-right"
        autoClose={5000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
      />
      <div className="projects" id="projects">
        <div className="projects__Left">
          <div className="projects__Header">
            <h2 className="medium__Fonts">
              This Months ({currentMonthResult?.length})
            </h2>
          </div>

          <ProjectsDataTable
            data={currentMonthResult}
            handleChange={handleChange}
          />

          {!addProject ? (
            <div className="addProject">
              <AddProject handleAddProject={handleAddProject} />
              <Button
                value="Cancel"
                onClick={handleAddProject}
                styleClass="danger__Button verySmall__Fonts"
              />
            </div>
          ) : (
            <FaPlusCircle
              onClick={handleAddProject}
              className="projects__AddProject"
            />
          )}
          <h2 className="medium__Fonts">
            Upcoming ({nextMonthResult?.length})
          </h2>
          <ProjectsDataTable
            data={nextMonthResult}
            handleChange={handleChange}
          />
        </div>

        <div className="projects__Right">
          <Activities />
        </div>
      </div>
    </>
  );
}

export default Projects;
