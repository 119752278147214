import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { collection, getDocs, query, where } from "firebase/firestore";
import { db } from "../../components/firebase";

const initialState = {
  users: [],
  loading: false,
};

export const getUsers = createAsyncThunk("users/getUsers", async () => {
  const q = query(collection(db, "users"), where("isAdmin", "==", true));
  const querySnapshot = await getDocs(q);

  const getUsersData = querySnapshot.docs.map((doc) => ({
    ...doc.data(),
    id: doc.id,
  }));
  return getUsersData;
});
export const usersSlice = createSlice({
  name: "users",
  initialState,
  reducers: {},
  extraReducers: {
    [getUsers.pending]: (state) => {
      state.loading = true;
    },
    [getUsers.fulfilled]: (state, { payload }) => {
      state.loading = false;
      state.users = payload;
    },
    [getUsers.rejected]: (state) => {
      state.loading = false;
    },
  },
});

export const selectUsers = (state) => state.users.users;

export default usersSlice.reducer;
