import "./Button.css";
const Button = ({ styleClass, value, onClick }) => {
  return (
    <div>
      <button
        className={`${styleClass != null ? styleClass : "feature__Btn"}`}
        onClick={onClick}
      >
        {value}
      </button>
    </div>
  );
};

export default Button;
