import React, { useState } from "react";
import "./TaskManager.css";
import DatePicker from "react-datepicker";

import "react-datepicker/dist/react-datepicker.css";
import Button from "../../libs/Button";
import { addDoc, collection } from "firebase/firestore";
import { MdOutlineClose } from "react-icons/md";
import ScheduleIcon from "../images/schedule__Icon.svg";
import { useFormInput } from "../../hooks/useFormInput";

import { db } from "../firebase";
import Schedule from "./Schedule";
import { useSelector } from "react-redux";
import { selectUser } from "../../app/slices/userSlice";

function TaskManager() {
  const [dates, setDates] = useState(null);
  const [title, resetTitle] = useFormInput("");
  const [name, resetName] = useFormInput("");
  const [company, resetCompany] = useFormInput("");
  const [success, resetSuccess] = useFormInput(false);

  const scheduleRef = collection(db, "schedule");
  const user = useSelector(selectUser);
  const handleSubmit = async (e) => {
    if (name.length > 0 && title.length > 0 && company.length > 0) {
      console.log("Error creating Task");
    }
    e.preventDefault();
    const newSchedule = {
      dates: new Date(dates).toDateString(),
      times: new Date(dates).toTimeString(),
      title: title.value,
      name: name.value,
      company: company.value,
      createdBy: user.displayName,
    };

    await addSchedule(newSchedule);
    resetSuccess(true);
    resetTitle("");
    resetName("");
    resetCompany("");
    setDates(null);
  };

  const handleClose = (e) => {
    e.preventDefault();

    resetSuccess(false);
  };

  const addSchedule = (newSchedule) => {
    return addDoc(scheduleRef, newSchedule);
  };

  return (
    <>
      {success.value ? (
        <div className="contact__Success scale-up-ver-center">
          <img
            src={ScheduleIcon}
            alt="Schedule Icon"
            className="strategy__SVG"
          />
          <br />
          <h5 className="h5__Font h5__Success">
            You successfully scheduled a meeting with {company.value}
          </h5>
          <MdOutlineClose onClick={handleClose} className="close__Pointer" />
        </div>
      ) : (
        ""
      )}{" "}
      <div className="taskManager" data-aos="fade-right">
        <div className="taskManager__Left">
          <h2 className="medium__Fonts">Taskmanager</h2>
          <div className="taskManager__Header">
            <h3 className="small__Fonts">Schedule Meeting</h3>
            <form
              type="submit"
              className="taskManager__Form"
              onSubmit={handleSubmit}
            >
              <div className="form__Input">
                <input
                  type="text"
                  className="contact__Name taskManager__Padding"
                  placeholder="Give a title for a meeting"
                  {...title}
                />
              </div>
              <div className="form__Input">
                <input
                  type="text"
                  className="contact__Name taskManager__Padding"
                  placeholder="Company Name"
                  {...company}
                />
              </div>

              <div className="form__Input">
                <input
                  type="text"
                  className="contact__Name taskManager__Padding"
                  placeholder="Person Name"
                  {...name}
                />
              </div>
              <DatePicker
                className="taskManager__Date"
                showTimeSelect
                dateFormat="dd/MM/yyyy"
                // dateFormat="MMMM d, yyyy h:mm aa"
                selected={dates}
                onChange={(date) => setDates(date)}
                placeholderText="Pick a date"
              />

              <Button value="Schedule Now" onClick={handleSubmit} />
            </form>
          </div>
        </div>
        <div className="taskManager__Right">
          <Schedule />
        </div>
      </div>
    </>
  );
}

export default TaskManager;
