import React from "react";
import { useSelector } from "react-redux";
import { selectUser } from "../../../app/slices/userSlice";
import "./Profile.css";
import { AiOutlineUser } from "react-icons/ai";
import { FiActivity, FiLogOut } from "react-icons/fi";
import { MdGroup } from "react-icons/md";
import { FaTasks } from "react-icons/fa";
import { BsKeyboard } from "react-icons/bs";
import { IoFootsteps } from "react-icons/io5";
import { auth } from "../../firebase";
function Profile() {
  const user = useSelector(selectUser);

  const signedUser = auth.onAuthStateChanged((user) => console.log(user));

  return (
    <div>
      <div className="profile__Header border__Bottom">
        <h3 className="h5__Color h5__Font">
          Hey, <span style={{ color: `orange` }}>{user.displayName}</span>
          <span className="h2__Title">👋</span>
        </h3>
      </div>
      <div className="common__Flex">
        <div className="reusable__Tab">
          <AiOutlineUser className="common__Icon__Size" />
          <h4 className="h5__Color h5__Font">Profile</h4>
        </div>
        <div className="reusable__Tab">
          <FiActivity className="common__Icon__Size" />
          <h4 className="h5__Color h5__Font">Activity</h4>
        </div>
        <div className="reusable__Tab">
          <MdGroup className="common__Icon__Size" />
          <h4 className="h5__Color h5__Font">Teams</h4>
        </div>
        <div className="reusable__Tab">
          <FaTasks className="common__Icon__Size" />
          <h4 className="h5__Color h5__Font">My Work</h4>
        </div>

        <div className="reusable__Tab">
          <FiLogOut className="common__Icon__Size" />
          <h4 className="h5__Color h5__Font">Logout</h4>
        </div>
      </div>
      <div className="common__Border__Bottom"></div>
      <div className="profile__Explore__Software common__Flex">
        <h4 className="common__Title">Explore Software</h4>
        <div className="reusable__Tab">
          <IoFootsteps className="common__Icon__Size" />
          <h4 className="h5__Color h5__Font">Learn</h4>
        </div>
        <div className="reusable__Tab">
          <IoFootsteps className="common__Icon__Size" />
          <h4 className="h5__Color h5__Font">Quick Links</h4>
        </div>
        <div className="reusable__Tab">
          <BsKeyboard className="common__Icon__Size" />
          <h4 className="h5__Color h5__Font">+Shortcuts</h4>
        </div>
      </div>
    </div>
  );
}

export default Profile;
