import { signInWithPopup } from "firebase/auth";
import { auth, googleProvider } from "../components/firebase";
import { useNavigate, NavLink } from "react-router-dom";
import { useDispatch } from "react-redux";
import { useAppContext } from "../libs/contextLib";

import "./Login.css";
import { useEffect } from "react";
function Login() {
  const { main } = useAppContext();

  useEffect(() => {
    if (main !== null) {
      history("/admin");
    }
  });

  const history = useNavigate();
  const dispatch = useDispatch();
  const signInWithGoogle = async () => {
    try {
      const res = await signInWithPopup(auth, googleProvider);
      const user = res.user;
      localStorage.setItem("admin", JSON.stringify(main));

      history("/");
    } catch (err) {}
  };

  return (
    <div className="login text-focus-in">
      <div className="login__Image"></div>
      <div className="login__Button">
        <NavLink className="login__Problem" value="Go back" to="/">
          Go back
        </NavLink>

        <h2 className="h2__Title">Login with your Gmail</h2>
        <button className="login__Google" onClick={signInWithGoogle}>
          Sign In
        </button>

        <h5 className="h5__Font h5__Color login__Problem">
          Having problem with signin ?
        </h5>
      </div>
    </div>
  );
}

export default Login;
