import { useEffect, useState } from "react";
import "./CreateChat.css";

import { FaPlusCircle } from "react-icons/fa";
import { useDispatch, useSelector } from "react-redux";
import { getUsers } from "../../app/slices/usersSlice";
import ChatDataService from "../../libs/crud";
import Button from "../../libs/Button";
import { selectUser } from "../../app/slices/userSlice";
function CreateChat() {
  const [chatTitle, setChatTitle] = useState("");
  const [open, setOpen] = useState(false);
  const [users, setUsers] = useState([]);
  const [message, setMessage] = useState({ error: false, msg: "" });

  const dispatch = useDispatch();

  useEffect(() => {
    const unSub = () => {
      dispatch(getUsers());
    };

    return unSub();
  }, []);
  const usersData = useSelector((state) => state.users.users);
  const user = useSelector(selectUser);

  const handleSubmit = async (e) => {
    e.preventDefault();
    setMessage("");

    if (chatTitle === "" || users.length === 0) {
      setMessage({ error: true, msg: "All fieled are required" });
    }

    const chatData = {
      name: chatTitle,
      createAt: new Date().getTime(),
      users: users,
      id: user?.uid || "",
      createdBy: user.displayName,
    };

    try {
      await ChatDataService.createChat(chatData, "chats");
      setMessage({ error: false, msg: "New Book added successfully" });
    } catch {
      setMessage({ error: true, msg: "Error adding book" });
    }

    console.log(chatData);
    setChatTitle("");
    setUsers([]);
    setOpen(!open);
  };

  //   console.log(window.location);
  return (
    <>
      <Button value="Create ChatRoom" onClick={() => setOpen(!open)} />

      {open && (
        <>
          <div className="create__ChatModal scale-up-ver-center create__ChatBack">
            <h5 className="medium__Fonts">Create Group</h5>

            <div className="createChat__InputField">
              <h5 className="danger__Color h5__Font">Title</h5>
              <input
                type="text"
                value={chatTitle}
                onChange={(e) => setChatTitle(e.target.value)}
                placeholder="Give a name to your group"
                className="createChat__Input"
              />
            </div>

            <div className="create__SelectUsers">
              {usersData?.map((user) => (
                <div key={user.id} className="create__SelectUser">
                  <img
                    key={user.id}
                    className="assign__Image assign__ImageMargin"
                    src={user?.photoURL}
                    onClick={() => setUsers(user?.name)}
                  />

                  <h5 className="verySmall__Fonts">
                    {user?.name?.toUpperCase()}
                  </h5>
                  <h5 className="verySmall__Fonts">Role</h5>
                </div>
              ))}
            </div>
            <div className="button__Group">
              <Button
                value="Create Group"
                onClick={handleSubmit}
                styleClass="button__OutlineGreen"
              />
              <Button
                onClick={() => setOpen(!open)}
                value="Cancel"
                styleClass="danger__Button"
              />
            </div>
          </div>
        </>
      )}
    </>
  );
}

export default CreateChat;
