import Button from "./Button";
import "../css/common.css";
import { MdOutlineClose } from "react-icons/md";

function CommonHeader({
  styleClass,
  data,
  onClick,
  name,
  cols,
  setCommentBox,
  buttonName,
  rows,
  title,
}) {
  return (
    <div className={styleClass}>
      {setCommentBox && (
        <MdOutlineClose
          onClick={() => setCommentBox(false)}
          className="close__Pointer"
        />
      )}

      <textarea
        {...data}
        className="common__Input"
        rows={rows === null ? "3" : rows}
        cols={cols}
        placeholder={
          title !== undefined ? title : `Write an update for ${name}...`
        }
      />

      <Button
        styleClass="common__Button margin__Left"
        value={buttonName}
        onClick={onClick}
      />
    </div>
  );
}

export default CommonHeader;
