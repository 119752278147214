import {
  addDoc,
  arrayUnion,
  collection,
  deleteDoc,
  doc,
  getDoc,
  getDocs,
  updateDoc,
} from "firebase/firestore";
import { db } from "../components/firebase";
import { toast } from "react-toastify";

const resourceRef = (resourceName) => collection(db, resourceName);
const nestedResourceDoc = (
  resourceName,
  resourceId,
  subResourceName,
  subResourceId
) => doc(db, resourceName, resourceId, subResourceName, subResourceId);

// Nested Ref
const nestedRef = (resourceName, id, subResourceName) =>
  collection(db, resourceName, id, subResourceName);

// Get Data with Queries

class ResourceService {
  // Create

  createResource = async (newResource, resourceName) => {
    return await addDoc(resourceRef(resourceName), newResource).then(() => {
      toast.success(`${newResource.name} added successfully`);
    });
  };
  createNestedResource = async (
    resourceName,
    id,
    subResourceName,
    newResource
  ) => {
    return await addDoc(
      nestedRef(resourceName, id, subResourceName),
      newResource
    );
  };

  //   Update
  updateResource = (id, updateResource, resourceName) => {
    const resourceDoc = doc(db, resourceName, id);
    return updateDoc(resourceDoc, updateResource);
  };

  nestedUpdateResource = async (
    resourceName,
    resourceId,
    subResourceName,
    subResourceId,
    commentsData
  ) => {
    // const nestedDoc= ne

    return await updateDoc(
      nestedResourceDoc(
        resourceName,
        resourceId,
        subResourceName,
        subResourceId
      ),
      { comments: arrayUnion(commentsData) }
    );
  };
  //   Delete

  deleteResource = (resourceName, id) => {
    const resourceDoc = doc(db, resourceName, id);
    return deleteDoc(resourceDoc, id);
  };

  // deleteNestedResource =async()=>{

  // }
  getAllResource = (resourceName) => {
    return getDocs(resourceRef(resourceName));
  };

  getResource = async (id, resourceName) => {
    const resourceDoc = doc(db, resourceName, id);
    return await (await getDoc(resourceDoc)).data();
  };
}

export default new ResourceService();
