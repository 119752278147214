import { useState } from "react";
import Footer from "../Footer";
import Header from "../Header";
import "./Client.css";
import Search from "./Search";
import GridService from "./GridService";
import Button from "../../libs/Button";

function Client() {
  const [searchTerm, setSearchTerm] = useState("");
  // const [adOpen, setAdOpen] = useState(true);
  return (
    <>
      <div className="client">
        <Header />

        <div className="bizkey__Ads">
          <div className="bizkey__Ad">
            <h2 className="h2__Salsa">Refer and Earn %</h2>
            <Button value="Use Code: BIZKEY30" />
            <div className="bizkey__AdContent">
              <h4 className="medium__Fonts">How it works?</h4>
              <h5 className="h5__Font h5__Color">
                On successful referral, you will get 30% off and your friend
                will get 15%
              </h5>
            </div>
          </div>
          <div className="client__Dashboard">
            {/* <h2 className="h2__Title">Dashboard</h2> */}

            <Search searchTerm={searchTerm} setSearchTerm={setSearchTerm} />
            <GridService />
          </div>
        </div>
      </div>
      <Footer />
    </>
  );
}

export default Client;
