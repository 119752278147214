import { BiCommentDetail } from "react-icons/bi";
import { AiOutlineLike } from "react-icons/ai";
import { BsChatSquareQuote } from "react-icons/bs";
function CommonBottom({ item, handleCommentBox, handleSelectedComment }) {
  return (
    <div className="common__Bottom">
      <div
        onClick={() => handleCommentBox(item.id)}
        className="common__Outline__Button common__Flex__Center"
      >
        <h5 className="common__Title">Comment</h5>
        <BiCommentDetail className="margin__Left common__Icon" />
      </div>
      <div
        onClick={() => handleSelectedComment(item.id)}
        className="common__Outline__Button common__Flex__Center"
      >
        <h5 className="common__Title">
          Replies(
          {item.comments?.length > 0 ? item.comments?.length : 0})
        </h5>
        <BsChatSquareQuote className="margin__Left common__Icon" />
      </div>
      <div className="common__Outline__Button common__Flex__Center">
        <>
          <h5 className="common__Title">Like</h5>

          <AiOutlineLike className="margin__Left common__Icon" />
        </>
      </div>
    </div>
  );
}

export default CommonBottom;
