import "./Automation.css";
import Button from "../../libs/Button";
import { useSelector } from "react-redux";
import { selectUsers } from "../../app/slices/usersSlice";
import axios from "axios";
import { useEffect, useState } from "react";
import { AiOutlineMail } from "react-icons/ai";
import { RiAdvertisementLine } from "react-icons/ri";

import { SiPagespeedinsights } from "react-icons/si";

import { BsInfoLg } from "react-icons/bs";
import MarketingCampaign from "./MarketingCampaign";
import { getAnalytics, logEvent } from "firebase/analytics";

import { FiActivity } from "react-icons/fi";

const analytics = getAnalytics();
logEvent(analytics, "notification_received");
function Automation() {
  const [email, setEmail] = useState([]);
  const [open, setOpen] = useState(false);
  const [campOpen, setCampOpen] = useState(false);
  const [analyticsOpen, setAnalticsOpen] = useState(false);
  const [cid, setCId] = useState("");

  const users = useSelector(selectUsers);

  useEffect(() => {
    unSub();
  }, []);

  const unSub = () => {
    if (users?.length > 0) {
      const userEmails = users?.map((data) => data?.email);

      setEmail(userEmails);
    }
  };

  const startCampaign = () => {
    setOpen(true);
  };
  const handleSubmit = async (e) => {
    e.preventDefault();
    await axios.post(
      // "http://localhost:5001/bizkey-solution/us-central1/sendEmailToAllUsers/sendemails",
      process.env.REACT_APP_SENDGRID_CAMPAIGN,
      { email, cid }
    );

    setCId("");
    setOpen(false);
  };

  return (
    <div>
      {open && (
        <div className="create__ChatModal scale-up-ver-center automation__Campaign">
          <BsInfoLg className="automation__Campaign__Info" />
          <h2 className="h2__Title automation__Title">
            Enter your Camapign Info
          </h2>
          <form onSubmit={handleSubmit}>
            <input
              type="text"
              placeholder="Campaign Id"
              value={cid}
              onChange={(e) => setCId(e.target.value)}
            />
            <div className="button__Bottom">
              <Button onClick={handleSubmit} value="Start Campaign" />
              <Button
                onClick={() => setOpen(!open)}
                value="Cancel"
                styleClass="verySmall__Button button__Color"
              />
            </div>
          </form>
        </div>
      )}

      {campOpen && <MarketingCampaign setCampOpen={setCampOpen} />}

      {analyticsOpen && <h3>Analytics </h3>}

      <div className="automation__Tabs">
        <div className="automation__Tab" onClick={startCampaign}>
          <AiOutlineMail className="h2__Title" />
          Send emails
        </div>
        <div className="automation__Tab" onClick={() => setCampOpen(true)}>
          <RiAdvertisementLine className="h2__Title" />
          Create Campign
        </div>
        <div
          className="automation__Tab"
          onClick={() => setAnalticsOpen(!analyticsOpen)}
        >
          <SiPagespeedinsights className="h2__Title" />
          Analytics
        </div>
        <div
          className="automation__Tab"
          onClick={() => setAnalticsOpen(!analyticsOpen)}
        >
          <FiActivity className="h2__Title" />
          Activities
        </div>
      </div>
    </div>
  );
}

export default Automation;
