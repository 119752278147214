import React from "react";
import "./NotFound.css";
import ErrorImage from "../components/images/notFound.svg";
import Button from "./Button";
import NavbarLinks from "./NavbarLinks";
import Footer from "../components/Footer";
function NotFound() {
  return (
    <>
      <div className="notFound">
        <button className="danger__Button">
          <NavbarLinks to="/" value="Go Back" />
        </button>

        {/* <Button value="Go Back" /> */}
        <img src={ErrorImage} alt="Not Found" className="notFound__Image" />
      </div>
      <Footer />
    </>
  );
}

export default NotFound;
