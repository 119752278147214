const currentDate = new Date();
const monthDays = new Date().getMonth() + 1;
const prsDate = new Date();

const date = new Date();
const currentYear = date.getFullYear();
const currentMonth = date.getMonth() + 1; // 👈️ months are 0-based

// 👇️ Current Month
const daysInCurrentMonth = getDaysInMonth(currentYear, currentMonth);
// const daysLeft = Math.round((1646121600000 - prsDate) / oneDay);
const oneDay = 1000 * 60 * 60 * 24;

let projects = [1, 2, 3, 4, 5];
const currentMonthData = projects.filter(
  (data) => Math.round((data.dates - prsDate) / oneDay) <= monthDays
);

export function getDaysInMonth(year, month) {
  return new Date(year, month, 0).getDate();
}

// export const daysLeft =(projects,)
// const dat2 = (data) => Math.round((data.dates - prsDate) / oneDay) <= monthDays;

export function getDay33(data, prsDate, oneDay, monthDays) {
  const dat2 = (data) =>
    Math.round((data.dates - prsDate) / oneDay) <= monthDays;
}

let dateToday = new Date();
let lastDayOfMonth = new Date(
  dateToday.getFullYear(),
  dateToday.getMonth() + 1,
  0
).getDate();
const daysUntilEndOfMonth = lastDayOfMonth - dateToday.getDate();

export { prsDate, oneDay, daysInCurrentMonth, monthDays, daysUntilEndOfMonth };
