import React, { useState } from "react";
import "./Tabs.css";
import { HiPlusCircle } from "react-icons/hi";
import { FaDatabase, FaMoneyCheckAlt } from "react-icons/fa";
import { RiAdminLine } from "react-icons/ri";
import { VscSettingsGear } from "react-icons/vsc";
import { AiOutlineClockCircle } from "react-icons/ai";
import AllBlogs from "./AllBlogs";
import AddBlog from "./AddBlog";
import Invoice from "./Invoice";
import AddAdmin from "./AddAdmin";
import Reminder from "./Reminder";
import Automation from "./Automation";
function Tabs() {
  const [toggle, setToggle] = useState(
    JSON.parse(localStorage.getItem("toggleId")) || 1
  );

  const toggleTab = (i) => {
    setToggle(i);
    localStorage.setItem("toggleId", JSON.stringify(i));
  };

  return (
    <div className="tabs">
      <ul className="nav">
        <li
          onClick={() => toggleTab(1)}
          className={toggle === 1 ? "tab active-tab" : "tab"}
        >
          <FaDatabase className="tabs__Icons" />
        </li>
        <li
          onClick={() => toggleTab(2)}
          className={toggle === 2 ? "tab active-tab" : "tab"}
        >
          <HiPlusCircle className="tabs__Icons" />
        </li>
        <li
          className={toggle === 3 ? "tab active-tab" : "tab"}
          onClick={() => toggleTab(3)}
        >
          <RiAdminLine className="tabs__Icons" />
        </li>
        <li
          className={toggle === 4 ? "tab active-tab" : "tab"}
          onClick={() => toggleTab(4)}
        >
          <FaMoneyCheckAlt className="tabs__Icons" />
        </li>
        <li
          className={toggle === 5 ? "tab active-tab" : "tab"}
          onClick={() => toggleTab(5)}
        >
          <AiOutlineClockCircle className="tabs__Icons" />
        </li>
        <li
          className={toggle === 6 ? "tab active-tab" : "tab"}
          onClick={() => toggleTab(6)}
        >
          <VscSettingsGear className="tabs__Icons" />
        </li>
      </ul>

      <div className="tab__Content">
        <div className={toggle === 1 ? "content active-content" : "content"}>
          <AllBlogs />
        </div>
        <div className={toggle === 2 ? "content active-content" : "content"}>
          <AddBlog />
        </div>
        <div className={toggle === 3 ? "content active-content" : "content"}>
          <AddAdmin />
        </div>

        <div className={toggle === 4 ? "content active-content" : "content"}>
          <Invoice />
        </div>
        <div className={toggle === 5 ? "content active-content" : "content"}>
          <Reminder />
        </div>
        <div className={toggle === 6 ? "content active-content" : "content"}>
          <Automation />
        </div>
      </div>
    </div>
  );
}

export default Tabs;
