function Sticky() {
  return (
    <div>
      <a href="/#contact">
        <button className="sticky1">
          <i className="fas fa-question"></i>
        </button>
      </a>
    </div>
  );
}

export default Sticky;
