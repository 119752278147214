import Draggable from "react-draggable";
import { v4 as uuidv4 } from "uuid";
import { useState, useEffect } from "react";
import { MdOutlineClose } from "react-icons/md";
import "./DiscussionBoard.css";
var randomColor = require("randomcolor");
function DiscussionBoard() {
  const [item, setItem] = useState("");
  const [items, setItems] = useState(
    JSON.parse(localStorage?.getItem("items")) ||
    []
  );

  const newitem = () => {
    if (item.trim() !== "") {
      const newitem = {
        id: uuidv4(),
        item: item,
        color: randomColor({
          luminosity: "light",
        }),
        defaultPos: { x: 100, y: 0 },
      };
      setItems((items) => [...items, newitem]);
      setItem("");
    } else {
      alert("Enter a item");
      setItem("");
    }
  };

  const keyPress = (event) => {
    var code = event.keyCode || event.which;
    if (code === 13) {
      newitem();
    }
  };

  useEffect(() => {
    localStorage.setItem("items", JSON.stringify(items));
  }, [items]);

  const updatePos = (data, index) => {
    let newArr = [...items];
    newArr[index].defaultPos = { x: data.x, y: data.y };
    setItems(newArr);
  };

  const deleteNote = (id) => {
    setItems(items.filter((item) => item.id !== id));
  };
  return (
    <div className="App">
      <div id="new-item">
        <input
          value={item}
          onChange={(e) => setItem(e.target.value)}
          placeholder="Enter something..."
          onKeyPress={(e) => keyPress(e)}
        />
        <button onClick={newitem}>ENTER</button>
      </div>
      <div id="items">
        {items.map((item, index) => {
          return (
            <Draggable
              key={item.id}
              defaultPosition={item.defaultPos}
              onStop={(e, data) => {
                updatePos(data, index);
              }}
            >
              <div style={{ backgroundColor: item.color }} className="box">
                <p style={{ margin: 0 }}>{item.item}</p>

                <MdOutlineClose
                  id="delete"
                  className=" danger__Color top__Extream__Right"
                  onClick={(e) => deleteNote(item.id)}
                />
              </div>
            </Draggable>
          );
        })}
      </div>
    </div>
  );
}

export default DiscussionBoard;
