import { deleteObject, ref, uploadBytesResumable } from "firebase/storage";
import { v4 } from "uuid";

import { toast } from "react-toastify";
import { storage } from "../components/firebase";
import { doc, updateDoc } from "firebase/firestore";
import ResourceService from "../libs/resource";

export const handleDeleteFile = (image) => {
  const storageRef = ref(storage, `${image}`);

  deleteObject(storageRef)
    .then(() => toast.success("Deleted successfully"))
    .catch((err) => toast.warn("Unauthorized"));
};
export const handleUpload = async (
  images,
  projectToggleData,
  setImages,
  name
) => {
  images.forEach(async (image) => {
    const meta = {
      customMetadata: { owner: name },
    };
    if (image == null) return;
    const storageRef = ref(
      storage,

      `projectsfiles/${projectToggleData.name}/${image.name + v4()}`
    );

    await uploadBytesResumable(storageRef, image, meta).then(() =>
      toast.success("File Uploaded Successfully")
    );
  });

  setImages([]);
};

// Used  in UseDropDown and Docs (Remove Selected Item)
export const handleRemoveSelectedItem = (item, state, setState) => {
  if (!state.some((current) => current.id === item.id)) {
    setState([...state, item]);
  } else {
    let selectionAfterRemoval = state;
    selectionAfterRemoval = selectionAfterRemoval.filter(
      (current) => current.id !== item.id
    );
    setState([...selectionAfterRemoval]);
  }
};

// Detach Project and Team  Before DELETE (teamId:"",projectId:"")

export const detachFromProject = async (data) => {
  const updateTeamData = {
    projectName: "",
    projectId: "",
    assigned: false,
  };
  await ResourceService.updateResource(
    data.id,
    updateTeamData,
    process.env.REACT_APP_TEAMS
  );
  const updateProjectData = {
    team: "",
    teamId: "",
  };

  if (data?.projectId !== "") {
    await ResourceService.updateResource(
      data.projectId,
      updateProjectData,
      process.env.REACT_APP_PROJECTS
    );
  }
};
