import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import Button from "./Button";
import { db } from "../components/firebase";
import "./Modal.css";
import { arrayUnion, doc, setDoc, updateDoc } from "firebase/firestore";
function Modal({ title, setUserSelectionModal, multiSelect, chatId }) {
  const [users, setUsers] = useState([]);

  const usersData = useSelector((state) => state.users?.users);

  const handleOnClick = async (item) => {
    if (!users.some((current) => current.id === item.id)) {
      // if (!multiSelect) {
      // setUsers([item]);
      // } else if (multiSelect) {
      setUsers([...users, item.id]);
      // }
    } else {
      let selectionAfterRemoval = users;
      selectionAfterRemoval = selectionAfterRemoval.filter(
        (current) => current.id !== item.id
      );
      setUsers([...selectionAfterRemoval]);
    }

    const assignUser = doc(db, "chats", chatId);

    await updateDoc(assignUser, {
      users: arrayUnion(item.id),
    });
  };

  console.log("Users are:", users);

  return (
    <div className="create__ChatModal scale-up-ver-center modal__AddUsers">
      <div className="modal__SelectedUsers">
        {users?.length > 0 &&
          users?.map((data) => (
            <div key={data.id}>
              <img
                src={data.photoURL}
                alt={data.email}
                className="assign__Image"
              />
            </div>
          ))}
      </div>
      <h4 className="medium__Fonts">{title}</h4>
      {usersData.length > 0 &&
        usersData.map((data) => (
          <div
            className="modal__Users"
            key={data.id}
            onClick={() => handleOnClick(data)}
          >
            <img
              className="assign__Image"
              src={data.photoURL}
              alt={data.name}
            />
            <h5 className="h5__Font h5__Color">{data.name.toUpperCase()}</h5>
            <h5 className="h5__Font h5__Color">{data.email}</h5>
          </div>
        ))}

      <div className="button__Group">
        <Button
          styleClass="danger__Button"
          value="Cancel"
          onClick={() => setUserSelectionModal(false)}
        />
      </div>
    </div>
  );
}

export default Modal;
