import { useState, useEffect } from "react";
import { useSelector } from "react-redux";
import { selectUser } from "../../app/slices/userSlice";
import Footer from "../Footer";
import Header from "../Header";
import "./Admin.css";
import Projects from "./Projects";
import Tabs from "./Tabs";
import TaskManager from "./TaskManager";
import { FaFileInvoiceDollar } from "react-icons/fa";
import { RiChat2Line } from "react-icons/ri";
import UserData from "./UserData";
import { CgMenuGridO } from "react-icons/cg";

import Draggable from "react-draggable";
import Button from "../../libs/Button";

import DiscussionBoard from "./DiscussionBoard";
import CommonModal from "../../libs/CommonModal";
import AdminSidebar from "./AdminSidebar";
import { useToggle } from "../../hooks/useToggle";

function Admin() {
  const [showTabs, setShowTabs] = useState(true);
  const [showSchedule, setShowSchedule] = useState(true);
  const [menuOpen, setMenuOpen] = useState(false);

  const closeTabs = () => {
    setShowTabs(false);
  };

  const showSchedules = () => {
    setShowSchedule(true);
  };
  const closeSchedule = () => {
    setShowSchedule(false);
  };

  const showTab = () => {
    setShowTabs(true);
  };

  return (
    <>
      <div className="admin__Main">
        <AdminSidebar />
        <div className="admin">
          {/* <Header /> */}

          {/* <CommonModal styleClass="modals__Project common__Position">
          <DiscussionBoard />
        </CommonModal> */}
          {/* <UserData className="handy__SubMenu" /> */}

          <Draggable>
            <div className="admin__HandyTaskBar">
              <CgMenuGridO
                className="handyMenu"
                onClick={() => setMenuOpen(!menuOpen)}
              />
              {menuOpen && (
                <div className="swing-in-top-fwd">
                  <a href="#invoice">
                    <FaFileInvoiceDollar className="handy__SubMenu" />
                  </a>
                  <a href="#chats">
                    <RiChat2Line className="handy__SubMenu" />
                  </a>

                  <FaFileInvoiceDollar className="handy__SubMenu" />
                  <FaFileInvoiceDollar className="handy__SubMenu" />
                  <FaFileInvoiceDollar className="handy__SubMenu" />
                  <FaFileInvoiceDollar className="handy__SubMenu" />
                </div>
              )}
            </div>
          </Draggable>

          {showTabs ? (
            <div className="admin__Tabs">
              <h3
                className="h5__Color h5__Font showContainer"
                onClick={closeTabs}
              >
                Hide Opeartions
              </h3>
              <Tabs />
            </div>
          ) : (
            <h5 onClick={showTab} className="h5__Font h5__Color showContainer">
              Show Operations
            </h5>
          )}
          {showSchedule ? (
            <div className="admin__Tabs">
              <h3
                className="h5__Color h5__Font showContainer"
                onClick={closeSchedule}
              >
                Hide Schedule
              </h3>

              <TaskManager />
            </div>
          ) : (
            <h5
              onClick={showSchedules}
              className="h5__Font h5__Color showContainer"
            >
              Show Schedule
            </h5>
          )}
          <Projects />
        </div>
      </div>

      <Footer />
    </>
  );
}

export default Admin;
