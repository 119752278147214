import { useEffect, useState } from "react";
import { AiOutlinePlus } from "react-icons/ai";
import Button from "../../libs/Button";
import "./Teams.css";
import { useSelector } from "react-redux";
import {
  collection,
  getDocs,
  query,
  where,
  addDoc,
  doc,
  onSnapshot,
  runTransaction,
} from "firebase/firestore";
import { db } from "../firebase";
import { selectUser } from "../../app/slices/userSlice";
import { handleRemoveSelectedItem } from "../../libs/commonFunctions";

function UsersDropdown() {
  const [open, setOpen] = useState(false);
  const [users2, setUsers2] = useState([]);
  const [users1, setUsers1] = useState([]);
  const [allUsers, setAllUsers] = useState([]);

  const [teamName, setTeamName] = useState("");
  const [showTeamForm, setShowTeamForm] = useState(false);

  useEffect(() => {
    let isMounted = true; // note mutable flag

    const q = query(collection(db, "users"), where("isAdmin", "==", true));

    onSnapshot(q, (doc) => {
      if (isMounted) {
        setAllUsers(
          doc.docs.map((teamd) => ({ ...teamd.data(), id: teamd.id }))
        );
      }
    });
    return () => {
      isMounted = false;
    };
  }, []);

  const signedUser = useSelector(selectUser);

  const fetchUsers = async () => {
    const q = query(collection(db, "users"), where("isAdmin", "==", true));
    const querySnapshot = await getDocs(q);

    setUsers1(
      querySnapshot.docs.map((doc) => ({
        ...doc.data(),
        id: doc.id,
      }))
    );
  };

  // Add Team Members

  const addTeam = () => {
    setOpen(!open);
    fetchUsers();
  };

  // Create Team
  const teamRef = collection(db, "teams");

  const createTeam = async (e) => {
    e.preventDefault();

    const newTeam = {
      createdBy: signedUser.displayName,
      uid: signedUser.uid,
      assigned: false,
      name:
        teamName +
        "-" +
        new Date().toDateString().slice(0, 10).toLowerCase().trim(),
      users: users2.map((data) => ({
        id: data.id,
        name: data.email.substring(0, data.email.lastIndexOf("@")),
        photoURL: data.photoURL,
        email: data.email,
      })),
    };

    users2.forEach(async (data) => {
      const useRef = doc(db, "users", `${data.id}`);

      await runTransaction(db, async (transaction) => {
        const sfDoc = await transaction.get(useRef);
        if (!sfDoc.exists()) {
          throw "Document does not exist!";
        }

        const newPopulation = sfDoc.data().workingOn + 1;

        transaction.update(useRef, { workingOn: newPopulation });
      });
    });
    addTeamRef(newTeam);
    setUsers2([]);
    setOpen(!open);
    setTeamName("");
  };
  //Add Team Ref

  const addTeamRef = (newTeams) => {
    return addDoc(teamRef, newTeams);
  };

  //

  const isUserSelect = (item) => {
    if (users2.find((current) => current.id === item.id)) {
      return true;
    } else return false;
  };

  return (
    <>
      {!showTeamForm ? (
        <Button
          value="Add Team"
          styleClass="listItem__Button"
          onClick={() => {
            setShowTeamForm(!showTeamForm);
          }}
        />
      ) : (
        <Button
          value="Cancel"
          styleClass="listItem__Button"
          onClick={() => {
            setShowTeamForm(!showTeamForm);
          }}
        />
      )}
      {showTeamForm && (
        <div className="teams__CreateForm">
          <h2 className="medium__Fonts">Create your team</h2>

          <input
            type="text"
            placeholder="Enter your team name"
            name="teamName"
            value={teamName}
            className="team__Input"
            onChange={(e) => setTeamName(e.target.value)}
          />
          <div className="dropdown">
            <button className="dropbtn" onClick={() => addTeam()}>
              Add Teammember
            </button>
            {open && (
              <div className="dropdown-content">
                {allUsers?.map((data) => (
                  <div
                    key={data.id}
                    className="team__User"
                    onClick={() =>
                      handleRemoveSelectedItem(data, users2, setUsers2)
                    }
                  >
                    <img src={data?.photoURL} className="message__photo" />
                    <h5 className="verySmall__Fonts">{data.name}</h5>
                    <h3 className="medium__Fonts">{data.workingOn}</h3>

                    {!isUserSelect(data) && <AiOutlinePlus />}
                  </div>
                ))}
              </div>
            )}
            <Button value="Create Team" onClick={createTeam} />
          </div>
        </div>
      )}
    </>
  );
}

export default UsersDropdown;
