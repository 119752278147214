import {
  addDoc,
  collection,
  deleteDoc,
  doc,
  getDoc,
  getDocs,
  updateDoc,
} from "firebase/firestore";
import { db } from "../components/firebase";

const chatRef = (chatName) => collection(db, chatName);
class ChatDataService {
  // Create
  createChat = (newChat, chatName) => {
    return addDoc(chatRef(chatName), newChat);
  };

  //   Update
  updateChat = (id, updateChat) => {
    const chatDoc = doc(db, "chats", id);
    return updateDoc(chatDoc, updateChat);
  };

  //   Delete

  deleteChat = (id) => {
    return deleteDoc(chatRef, id);
  };
  getAllChats = (chatName) => {
    return getDocs(chatRef(chatName));
  };

  getChat = (id) => {
    const chatDoc = doc(db, "chats", id);
    return getDoc(chatDoc);
  };
}

export default new ChatDataService();
