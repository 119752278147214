import { createSlice } from "@reduxjs/toolkit";

export const projectToggleSlice = createSlice({
  name: "projectToggle",
  initialState: {
    id: null,
    name: null,
    photo: null,
    createdBy: null,
    status: null,
  },
  reducers: {
    setProjectToggle: (state, action) => {
      state.id = action.payload.id;
      state.name = action.payload.name;
      state.photo = action.payload.photo;
      state.createdBy = action.payload.createdBy;
      state.status = action.payload.status;
      state.teamId = action.payload.teamId;
      state.teamName = action.payload.teamName;
    },
    logout: (state) => {
      state.projectToggle = null;
    },
  },
});

export const { setProjectToggle } = projectToggleSlice.actions;

export const selectProjectToggle = (state) => state.projectToggle;

export default projectToggleSlice.reducer;
