import {
  addDoc,
  collection,
  deleteDoc,
  doc,
  getDoc,
  getDocs,
  updateDoc,
} from "firebase/firestore";
import { db } from "../components/firebase";

const marketingRef = collection(db, "marketing");
class MarketingCrud {
  // Create
  creteMarketing = (newMarketing) => {
    return addDoc(marketingRef, newMarketing);
  };

  //   Update
  updateMarketing = (id, updateMarketing) => {
    const marketingDoc = doc(db, "marketing", id);
    return updateDoc(marketingDoc, updateMarketing);
  };

  //   Delete

  deleteMarketing = (id) => {
    return deleteDoc(marketingRef, id);
  };
  getAllMarketing = () => {
    return getDocs(marketingRef);
  };

  getMarketing = (id) => {
    const marketingDoc = doc(db, "marketing", id);
    return getDoc(marketingDoc);
  };
}

export default new MarketingCrud();
