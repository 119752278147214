import { useState } from "react";
import "./ReUsableTabs.css";
export default function ReUsableTabs({ tabs }) {
  const [activeTab, setActiveTab] = useState("0");
  return (
    <div key={activeTab.toString()}>
      <div className="reusable__Tabs">
        {tabs.map((tab, index) => (
          <div
            className="common__Title"
            key={index.toString()}
            active={
              activeTab.toString()
                ? activeTab.toString() === index.toString()
                : undefined
            }
            onClick={() => setActiveTab(index.toString())}
          >
            <h3
              className="common__Title reusable__Tab"
              active={
                activeTab.toString()
                  ? activeTab.toString() === index.toString()
                  : undefined
              }
            >
              {tab.title}
            </h3>

            <div
              active={
                activeTab.toString()
                  ? activeTab.toString() === index.toString()
                  : undefined
              }
            />
          </div>
        ))}
      </div>
      {tabs[activeTab.toString()].render()}
    </div>
  );
}
