import "./MarketingCampaign.css";
import { useState } from "react";
import { useSelector } from "react-redux";
import { selectUser } from "../../app/slices/userSlice";
import MarketingCrud from "../../libs/markcrud";
import DatePicker from "react-datepicker";
import Button from "../../libs/Button";
import { FaPlusCircle } from "react-icons/fa";
import { MdOutlineClose } from "react-icons/md";

function MarketingCampaign({ setCampOpen }) {
  const [campaignName, setCampaignName] = useState("");
  const [campaignDescription, setCampaignDescription] = useState("");
  const [campaignId, setCampaignId] = useState("");
  const [message, setMessage] = useState({ error: false, msg: "" });
  const [publish, setPublish] = useState("");
  const [dates, setDates] = useState(null);
  const [emails, setEmails] = useState([]);
  const [input, setInput] = useState("");
  const [errorAlert, setErrorAlert] = useState(false);
  const [sent, setSent] = useState(false);
  const user = useSelector(selectUser);

  console.log("Selected Dates are:", dates);
  const ids = ["d-12312", "d-123213", "d-13"];
  const checkCampId = () => {
    if (!ids.some((data) => data === campaignId)) {
      return campaignId;
    } else {
      console.log("Id alredy exists add");
    }
  };
  const handleSubmit = async (e) => {
    e.preventDefault();
    setMessage("");

    setErrorAlert(!errorAlert);

    const campaignData = {
      campaignName: campaignName,
      campaignId: checkCampId(),
      campaignDescription: campaignDescription,
      createAt: new Date(),
      publishDate: dates,
      sent: sent,
      emails: emails,

      email: user?.email,
    };

    try {
      if (campaignName === "" || campaignId === "" || emails === []) {
        setMessage({ error: true, msg: "All fieled are required" });
        return;
      }
      await MarketingCrud.creteMarketing(campaignData);
      setMessage({ error: false, msg: "New Campaign added successfully" });
      setCampaignDescription("");
      setCampaignName("");
      setCampaignId("");
      setCampOpen(false);
    } catch {
      setMessage({ error: true, msg: "Error creating campaign" });
    }
  };

  const addUsers = (e) => {
    if (!emails.some((data) => data === input)) {
      console.log(emails);
      if (input === "") return;
      setEmails([...emails, input]);
      setInput("");
    } else {
      console.log("Error adding email");
    }
  };

  const remove = (data) => {
    let selectedEmails = emails;

    selectedEmails = selectedEmails.filter((current) => current !== data);

    setEmails([...selectedEmails]);
    console.log(`You selected ${data}`);
  };

  return (
    <div className="create__ChatModal scale-up-ver-center marekting__Camp">
      {message?.msg && errorAlert === true ? (
        <div className="scale-in-hor-left alert">
          <h5 className="small__Fonts">Error:{message.msg} </h5>
          <MdOutlineClose
            className="close__Pointer"
            onClick={() => setErrorAlert(!errorAlert)}
          />
        </div>
      ) : (
        ""
      )}
      <h2 className="h2__Title">Start Campaign </h2>
      <div className="input__Field">
        <DatePicker
          showTimeSelect
          dateFormat="Pp"
          // dateFormat="MMMM d, yyyy h:mm aa"
          selected={dates}
          onChange={(date) => setDates(date)}
          placeholderText="Pick a date(optional)"
          className="danger__Button"
        />
      </div>

      <div className="input__Field">
        <input
          type="text"
          value={campaignName}
          placeholder="Give a meaningful Name"
          onChange={(e) => setCampaignName(e.target.value)}
          className="campaign__Title"
        />
      </div>
      <div className="input__Field">
        <input
          type="text"
          value={campaignDescription}
          placeholder="Description (optional)"
          onChange={(e) => setCampaignDescription(e.target.value)}
          className="campaign__Title"
        />
      </div>
      <div className="input__Field">
        <input
          type="text"
          value={campaignId}
          placeholder="Campaign Id"
          onChange={(e) => setCampaignId(e.target.value)}
          className="campaign__Title"
        />
      </div>

      <div className="whiteBack">
        <input
          type="email"
          value={input}
          placeholder="Users Email"
          onChange={(e) => setInput(e.target.value)}
          className="marketingCampaign__Emails"
        />
        <FaPlusCircle
          onClick={() => addUsers()}
          className="medium__Fonts marketingCampaign__Button"
        />
      </div>
      {emails?.length > 0 && (
        <div className="marketing__Emails">
          {emails.map((data) => (
            <h5
              key={data}
              onClick={() => remove(data)}
              className="verySmall__Fonts medium__Button "
            >
              {data}
            </h5>
          ))}
        </div>
      )}

      <div className="input__Field">
        <Button value="Send Campaign" onClick={handleSubmit} />
        <Button
          value="Cancel"
          onClick={() => setCampOpen(false)}
          styleClass="verySmall__Button button__Color"
        />
      </div>
    </div>
  );
}

export default MarketingCampaign;
