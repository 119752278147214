import { useState } from "react";
import { storage } from "../firebase";
import { getDownloadURL, ref, uploadBytesResumable } from "firebase/storage";
import ResourceService from "../../libs/resource";
import { useToggle } from "../../hooks/useToggle";
import BatchJobs from "./BatchJobs";
import "./AddBlog.css";
import { useFormInput } from "../../hooks/useFormInput";
function AddBlog() {
  const [progress, setProgress] = useState(0);

  const [urls, setUrls] = useState("");
  const [description, resetDescription] = useFormInput("");

  const { toggle: batchOpen, toggleStatus: handleBatchOpen } = useToggle();

  const [name, resetName] = useFormInput("");

  const handleUpload = (e) => {
    e.preventDefault();
    const file = e.target.files[0];
    uploadFiles(file);
  };

  const uploadFiles = (file) => {
    //
    if (!file) return;
    const sotrageRef = ref(storage, `files/${file.name}`);
    const uploadTask = uploadBytesResumable(sotrageRef, file);

    uploadTask.on(
      "state_changed",
      (snapshot) => {
        const prog = Math.round(
          (snapshot.bytesTransferred / snapshot.totalBytes) * 100
        );
        setProgress(prog);
      },
      (error) => console.log(error),
      () => {
        getDownloadURL(uploadTask.snapshot.ref).then((downloadURL) => {
          // name: file.name;
          setUrls(downloadURL);
        });
      }
    );
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (name.length < 0 && description.length < 0) {
      return;
    }
    const newBlog = {
      name: name.value,
      description: description.value,
      urls,
    };

    await ResourceService.createResource(newBlog, "blogs");

    console.log(newBlog);
    resetName();
    resetDescription();
    setUrls("");
  };

  return (
    <>
      {batchOpen && <BatchJobs handleBatchOpen={handleBatchOpen} />}
      <div className="addBlog scale-in-hor-left">
        <div className="batch">
          <h4 className="automation__Tab" onClick={handleBatchOpen}>
            Batch Jobs
          </h4>
        </div>

        <form type="submit" className="addBlog__Form" onSubmit={handleSubmit}>
          <h4 className="h2__Title">Write your blog</h4>
          <input
            className="contact__Name addBlog__Input"
            placeholder="Enter Blog Title"
            {...name}
          />

          <input
            className="contact__Name addBlog__Input"
            placeholder="Enter Blog Description (optional)"
            type="text"
            {...description}
          />

          <div className="addBlog__Image">
            <input
              className="contact__Name addBlog__Input"
              type="file"
              onChange={handleUpload}
            />
            {progress > 0 ? (
              <h3 className="h2__Title">Uploaded {progress}</h3>
            ) : (
              ""
            )}
          </div>

          <button className="addBlog__Button" onClick={handleSubmit}>
            Create New Blog
          </button>
        </form>
      </div>
    </>
  );
}

export default AddBlog;
