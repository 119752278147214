import ReactTooltip from "react-tooltip";

export const ReactToolTipData = ({ children, id, backColor }) => {
  return (
    <>
      <ReactTooltip
        id={id}
        place="top"
        effect="solid"
        backgroundColor={backColor ? backColor : "#EFEFEF"}
      >
        <div>{children}</div>
      </ReactTooltip>
    </>
  );
};

// projectHoverTip"
