import { collection, onSnapshot, query, where } from "firebase/firestore";
import { useState, useEffect } from "react";
import { useSelector } from "react-redux";
import { selectUser } from "../../../app/slices/userSlice";
import Button from "../../../libs/Button";
import { db } from "../../firebase";
import AdminSidebar from "../AdminSidebar";
import "./RecycleProject.css";
import Data from "../../images/svgs/TrashSvg";
import Footer from "../../Footer";
import moment from "moment";
import ResourceService from "../../../libs/resource";
import { toast } from "react-toastify";
function RecycleProject() {
  const [resource, setResource] = useState([]);
  const [message, setMessage] = useState(false);
  const [id, setId] = useState("");
  const userData = useSelector(selectUser);

  const getData = () => {
    try {
      const resourceData = collection(db, "recycle-bin");
      const q = query(resourceData, where("uid", "==", `${userData?.uid}`));
      onSnapshot(q, (doc) => {
        setResource(
          doc.docs.map((teamd) => ({ ...teamd.data(), id: teamd.id }))
        );
      });
    } catch (err) {
      console.log("Error loading data", err);
    }
  };

  const showDeleteMessage = (id) => {
    setMessage(!message);
    setId(id !== "" ? id : "");
  };

  const handleDelete = async (id) => {
    setMessage(!message);
    await ResourceService.deleteResource("recycle-bin", id);
    toast.info("Delete successful");
    setId("");
  };

  const restoreProject = async (data) => {
    await ResourceService.createResource(data.item, "projects");
    await ResourceService.deleteResource("recycle-bin", data.id);

    toast.success(`${data.name} restored successfully`);
  };
  return (
    <>
      <div className="admin__Main">
        <AdminSidebar />
        <div className="recycle__Projects">
          <h3 className="medium__Fonts">RecycleProject</h3>
          <Button onClick={getData} value="Get Data" />

          <div className="recycle__Main">
            {resource.length === 0 ? (
              <div className="recycle__NoData">
                {/* <img src={BinImage} className="recycle__Image" /> */}
                <Data />
                <h2 className="h2__Title danger__Color">No data found</h2>
              </div>
            ) : (
              resource.flatMap((data) => (
                <div
                  key={data.id}
                  className="recycle__Project margin__Top margin__Left"
                >
                  <div className="recycle__Project__Name">
                    <h2 className="common__Title">{data.item.name}</h2>
                  </div>
                  <div className="recycle__Project__Content">
                    {message && id !== "" && id === data.id ? (
                      <h3 className="common__Title">
                        will deleted permanently !!
                      </h3>
                    ) : (
                      <>
                        <h5 className="h5__Font margin__Left danger__Color">
                          Due: {moment(data.item.endDate).fromNow()}
                        </h5>
                        <h5 className="common__Title margin__Left">
                          Tags:{data.item.tags}
                        </h5>

                        <small className="verSmall__Font h5__Color top__Bottom">
                          Deleted @ {moment(data.deletedAt).fromNow()}
                        </small>
                      </>
                    )}
                  </div>
                  <div
                    style={{
                      display: "flex",
                      placeItems: "center",
                      paddingRight: "20px",
                      gap: "15px",
                    }}
                  >
                    <Button
                      onClick={() => restoreProject(data)}
                      value={message && id === data.id ? "Restore" : "Keep it"}
                    />
                    {message && id === data.id ? (
                      <Button
                        value="Delete Forever"
                        onClick={() => handleDelete(data.id)}
                        styleClass="feature__Danger__Btn"
                      />
                    ) : (
                      <Button
                        onClick={() => showDeleteMessage(data.id)}
                        value="Delete"
                        styleClass="feature__Danger__Btn"
                      />
                    )}
                  </div>
                </div>
              ))
            )}
          </div>
        </div>
      </div>
      <Footer />
    </>
  );
}

export default RecycleProject;
