import axios from "axios";
import Button from "../../libs/Button";
import { doc, updateDoc } from "firebase/firestore";
import "./Reminder.css";
import { db } from "../firebase";
import { useResource } from "../../hooks/useResource";
function Reminder() {
  const reminderData = useResource(process.env.REACT_APP_REMINDER);
  const sendReminder = async (data) => {
    await axios
      .post(process.env.REACT_APP_MANUAL_REMINDER, {
        price: data.price,
        service: data.service,
        email: data.email,
      })
      .then(() => {
        handleReminderStatus(data);
      });
  };

  const handleReminderStatus = async (data) => {
    const updateStatus = doc(db, process.env.REACT_APP_REMINDER, data.id);
    const updateData = {
      reminder: !data.reminder,
    };
    await updateDoc(updateStatus, updateData);
  };
  return (
    <div className="taskCard">
      <div className="div1">
        <h5 className="verySmall__Fonts">Index</h5>
        <h5 className="verySmall__Fonts">Name</h5>
        <h5 className="verySmall__Fonts">Email</h5>
        <h5 className="verySmall__Fonts">Company</h5>
        <h5 className="verySmall__Fonts">Service</h5>
        <h5 className="verySmall__Fonts">Fees</h5>
        <h5 className="verySmall__Fonts">Operations</h5>
      </div>
      {reminderData.length > 0 ? (
        reminderData.map((data, index) => (
          <div key={data.id} className="reminder__Main">
            <div className="div1">
              <h5 className="verySmall__Fonts">{index}</h5>

              <h5 className="verySmall__Fonts">{data.name}</h5>
              <h5 className="verySmall__Fonts">{data.email}</h5>
              <h5 className="verySmall__Fonts">{data.company}</h5>
              <h5 className="verySmall__Fonts">{data.service}</h5>
              <h5 className="verySmall__Fonts">{data.price}</h5>
              {data.reminder === false ? (
                <Button
                  onClick={() => sendReminder(data)}
                  styleClass="medium__Button"
                  value="Send Reminder"
                />
              ) : (
                <h5 className="h5__Font h5__Color">Sent</h5>
              )}
            </div>
          </div>
        ))
      ) : (
        <h2 className="h2__Title">No Data Found</h2>
      )}
    </div>
  );
}

export default Reminder;
