import { useEffect } from "react";
import "./AdminSidebar.css";
import Logo from "../../components/images/admin__Logo.svg";
import { ReactToolTipData } from "../../libs/ReactToolTipData";
import { NavLink } from "react-router-dom";
import { BsBell } from "react-icons/bs";
import { useToggle } from "../../hooks/useToggle";
import { MdGroup } from "react-icons/md";
import { BsFillCalendarDateFill, BsFillChatSquareFill } from "react-icons/bs";
import { BsSearch } from "react-icons/bs";
import { selectUser } from "../../app/slices/userSlice";
import { useSelector } from "react-redux";
import CommonModal from "../../libs/CommonModal";
import Profile from "./Profile/Profile";
import QuickSearch from "./Projects/QuickSearch";
import { signOut } from "firebase/auth";
import { auth } from "../firebase";
import { FaTrash } from "react-icons/fa";

function AdminSidebar() {
  const { toggle: profileOn, toggleStatus: handleProfile } = useToggle();
  const { toggle: insights, toggleStatus: handleInsights } = useToggle();

  const user = useSelector(selectUser);
  // const handleSignOut = async () => {
  //   signOut(auth);
  //   navigate("/");
  //   setMain(null);
  //   await JSON.parse(
  //     localStorage?.setItem("admin", JSON.stringify(null || ""))
  //   );
  // };
  useEffect(() => {
    let isMounted = true;
    document.addEventListener("keydown", (e) => {
      if (!e.ctrlKey) return;
      if (e.ctrlKey && e.key.toLowerCase() === "m") {
        if (isMounted) {
          handleInsights();

          console.log("You pressed ", e.ctrlKey + e.key);
        }
      }
    });

    return () => {
      isMounted = false;
    };
  }, [insights]);

  return (
    <>
      <div className="admin__Sidebar">
        <NavLink to="/admin">
          <img
            className="cursor__Pointer margin__Bottom margin__Top"
            src={Logo}
            data-tip
            data-for="adminLogo"
          />
        </NavLink>

        <ReactToolTipData id="adminLogo" backColor="#ffaa33">
          <h5 className="toolTip__Color">Home</h5>
        </ReactToolTipData>

        <NavLink to="/admin/notifications">
          <BsBell
            className="common__Icon__Size"
            alt="Notification"
            data-tip
            data-for="notification"
          />
          <ReactToolTipData id="notification">
            <h5 className="toolTip__Color">Notification</h5>
          </ReactToolTipData>
        </NavLink>

        <NavLink to="/admin/teams">
          <MdGroup
            className="common__Icon__Size cursor__Pointer"
            data-tip
            data-for="assignTeam"
          />
        </NavLink>
        <ReactToolTipData id="assignTeam">
          <h5 className="toolTip__Color">Assign Team</h5>
        </ReactToolTipData>

        <NavLink to="/admin/timeline">
          <BsFillCalendarDateFill
            className="common__Icon__Size"
            data-tip
            data-for="timeline"
          />
        </NavLink>
        <ReactToolTipData id="timeline">
          <h5 className="toolTip__Color">Upcoming Events</h5>
        </ReactToolTipData>

        <BsSearch
          className="common__Icon__Size cursor__Pointer"
          data-tip
          data-for="quickSearch"
          onClick={handleInsights}
        />
        <ReactToolTipData id="quickSearch">
          <h5 className="toolTip__Color">Quick Search(Ctrl+b)</h5>
        </ReactToolTipData>
        <NavLink to="/chats">
          <BsFillChatSquareFill
            className="common__Icon__Size cursor__Pointer"
            data-tip
            data-for="chats"
          />
        </NavLink>

        <ReactToolTipData id="chats">
          <h5 className="toolTip__Color">Chats</h5>
        </ReactToolTipData>

        <NavLink to="/admin/recycleBin">
          <FaTrash
            className="common__Icon__Size cursor__Pointer"
            data-tip
            data-for="recycle"
          />
        </NavLink>
        <ReactToolTipData id="recycle">
          <h5 className="toolTip__Color">Recycle Bin</h5>
        </ReactToolTipData>

        <>
          <div className="border__Top" />
          <img
            className="adminSidebar__Image cursor__Pointer"
            src={user?.photo}
            alt={user?.displayName}
            onClick={() => handleProfile()}
          />
        </>
      </div>

      {profileOn && (
        <CommonModal
          styleClass="modals__Project1 common__Position blueShade__Dark"
          onClose={handleProfile}
        >
          <Profile />
        </CommonModal>
      )}
      {insights && <QuickSearch handleInsights={handleInsights} />}
    </>
  );
}

export default AdminSidebar;
