import React from "react";
const AosLib = ({ value }) => {
  return (
    <>
      <h2
        data-aos="fade-right"
        data-aos-offset="300"
        data-aos-easing="ease-in-sine"
      >
        {value}
      </h2>
    </>
  );
};

export default AosLib;
