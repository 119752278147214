import "./GridService.css";
import Correct from "../images/correct.svg";
function GridService() {
  return (
    <div className="gridService">
      <div className="grids">
        <h4 className="grids__h4">ISO 2019 It's workds daasdad</h4>
        <h6 className="verySmall__Fonts">
          Executed Between 17 Jan 2022- 31 Jan 2022
        </h6>
        <div className="grid__Flex">
          <h6 className="grids__Editor">Paid</h6>
          <img src={Correct} alt="correct" />
        </div>

        <h5 className="grids__status1 green">Completed</h5>
      </div>
      <div className="grids ">
        <h4 className="grids__h4">ISO 2020</h4>
        <h6 className="verySmall__Fonts">
          Executed Between 17 Jan 2022- 31 Jan 2022
        </h6>
        <div className="grid__Flex">
          <h6 className="grids__Editor">Paid</h6>
          <img src={Correct} alt="correct" />
        </div>

        <div className="grids__MoreInfo">
          <h6 className="grids__Details outlined">View More</h6>
          <h6 className="grids__Details filled">Invoice</h6>
        </div>
        <h5 className="grids__status1 green">Completed</h5>
      </div>
      <div className="grids">
        <h4 className="grids__h4">HIPPA 2019</h4>
        <h6 className="verySmall__Fonts">
          Executed Between 17 Jan 2022- 31 Jan 2022
        </h6>
        <div className="grid__Flex">
          <h6 className="grids__Editor">Paid</h6>
          <img src={Correct} alt="correct" />
        </div>
        <h5 className="grids__status1 green">Completed</h5>
      </div>
      <div className="grids">
        <h4 className="grids__h4">FSSAI 2019</h4>

        <h5 className="grids__status1 green">Completed</h5>
      </div>
      <div className="grids">
        <h4 className="grids__h4">Training 2019</h4>

        <h5 className="grids__status1 red">Missing Docs</h5>
      </div>
      <div className="grids">
        <h4 className="grids__h4">Sign UP 2020</h4>

        <h5 className="grids__status1 yellow">In Progress</h5>
      </div>
      <div className="grids">
        <h4 className="grids__h4">Documenting 2021</h4>

        <h5 className="grids__status1 red">Missing Docs</h5>
      </div>
      <div className="grids">
        <h4 className="grids__h4">DOCS REUSED 2022</h4>

        <h5 className="grids__status1 yellow">In Progress</h5>
      </div>
    </div>
  );
}

export default GridService;
