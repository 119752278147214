import "./Activities.css";
import { useState, useEffect } from "react";
import { selectUser } from "../../../app/slices/userSlice";
import { useSelector } from "react-redux";
import moment from "moment";
import { useResourceQuery } from "../../../libs/useResourceQuery";
import {
  addDoc,
  collection,
  onSnapshot,
  query,
  where,
} from "firebase/firestore";
import { db } from "../../firebase";
import Button from "../../../libs/Button";

const Activities = () => {
  const [resource, setResource] = useState([]);
  const [showLogs, setShowLogs] = useState(false);
  const userData = useSelector(selectUser);

  const getData = () => {
    setShowLogs(!showLogs);
    try {
      const resourceData = collection(db, "projectLogs");
      const q = query(resourceData, where("updater", "==", `${userData?.uid}`));
      onSnapshot(q, (doc) => {
        setResource(
          doc.docs.map((teamd) => ({ ...teamd.data(), id: teamd.id }))
        );
      });
    } catch (err) {
      console.log("Error loading data", err);
    }
  };
  return (
    <div className="activities">
      <h4 className="common__Title h5__Color">
        Showing ({resource?.length}) count of logs/ activities
      </h4>
      <Button onClick={getData} value={showLogs ? "Hide" : "Show Logs"} />
      {resource.length > 0 &&
        showLogs &&
        resource.map((data) => (
          <div key={data.id}>
            <div className="medium__Button margin__Top activity">
              {data.update.includes("deleted") ? (
                <h5 className="danger__Color top__Extreme__Right verySmall__Fonts1">
                  Deleted
                </h5>
              ) : (
                ""
              )}
              {data.update.includes("created") ? (
                <h5 className="common__Color__Success top__Extreme__Right verySmall__Fonts1">
                  Created
                </h5>
              ) : (
                ""
              )}

              {data.update.includes("changed") ? (
                <h5 className="update__Color top__Extreme__Right verySmall__Fonts1">
                  Updated
                </h5>
              ) : (
                ""
              )}

              <h3 className="plain__White margin__Top">{data.update}</h3>
            </div>
            <h5 className="verySmall__Fonts1 margin__Left h5__Color">
              {moment(new Date(data.updateTime).getTime()).fromNow()}
            </h5>
          </div>
        ))}
    </div>
  );
};

export default Activities;
