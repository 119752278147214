import { NavLink } from "react-router-dom";
import { useResource } from "../../hooks/useResource";
import Button from "../../libs/Button";
import Footer from "../Footer";
import AdminSidebar from "./AdminSidebar";
import "./Timeline.css";
import TimelineItem from "./TimelineItem";
function Timeline() {
  const projectsData = useResource(process.env.REACT_APP_PROJECTS);
  const sortResult = projectsData.sort((a, b) => a.endDate - b.endDate);

  return (
    <>
      <div className="admin__Main">
        <AdminSidebar />
        <div className="timeline">
          <h2 className="h2__Title">Showing {projectsData?.length} results</h2>
          <div className="timeline__monthInfo">
            <div className="red" />
            <h5 className="h5__Font h5__Color">Due this Month</h5>
            <div className="yellow"></div>
            <h5 className="h5__Font h5__Color">Upcoming Month</h5>
          </div>

          <div className="timeline-container">
            {sortResult?.length > 0
              ? sortResult.map((data) => (
                  <TimelineItem data={data} key={data.id} />
                ))
              : "No Data"}
          </div>
        </div>
      </div>
      <Footer />
    </>
  );
}

export default Timeline;
