import React from "react";
import "./Comments.css";
import moment from "moment";
import { MdOutlineClose } from "react-icons/md";
function Comments({ commentData, setShowComments, showComments }) {
  return (
    <div key={commentData?.id}>
      <MdOutlineClose
        onClick={() => setShowComments(!showComments)}
        className="close__Pointer"
      />
      <div key={commentData?.id} className="halfPageModal__CommentBox__Replies">
        <div className="common__Card__Left__Header">
          <img
            className="profile__Small__Round"
            src={commentData?.userImage}
            alt={commentData?.userName}
          />
          <h4 className="common__Small__Title margin__Left">
            {commentData?.userName.toUpperCase()}
          </h4>
        </div>

        <h5 className="margin__Top common__Small__Title common__Line__Height">
          {commentData?.comment}
        </h5>
      </div>
      <h3 className="h5__Font h5__Color margin__Left">
        {moment(commentData?.createAt).fromNow()}
      </h3>
    </div>
  );
}

export default Comments;
